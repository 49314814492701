<p class='disclaimer'>Отзывы игроков предоставлены сайтом Stratege. В отзывах много спойлеров!</p>

<b-player-message
	*ngFor='let review of reviews'
	[message]='review'
></b-player-message>

<div class='link'>
<a
	href='https://www.stratege.ru/ps4/games/the_last_of_us_part_ii/'
	target='_blank'
	rel='noopener'
	class='btn'
>ОСТАВИТЬ ОТЗЫВ</a>

<p>После нажатия на кнопку вы будете переведены на сайт Stratege. Чтобы оставить отзыв необходимо зарегистрироваться на сайте и прикрепить свой игровой профиль </p>
</div>
