import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Message } from '@models/message.type';
import { parse } from '@components/message-parser/parse';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'b-message-parser',
	templateUrl: './message-parser.component.html',
	styleUrls: ['./message-parser.component.styl'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageParserComponent implements OnChanges, OnInit {
	@Input() message: Message;
	parsedMessage;

	constructor(private sanitizer: DomSanitizer) {

	}

	ngOnChanges(changes: SimpleChanges) {
		if ('message' in changes) {
			this.parseMessage();
		}
	}

	ngOnInit() {
		this.parseMessage();
	}

	private parseMessage() {
		this.parsedMessage = this.sanitizer.bypassSecurityTrustHtml(parse(this.message));
	}
}
