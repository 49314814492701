import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'b-gallery-detail',
	templateUrl: './gallery-detail.component.html',
	styleUrls: ['./gallery-detail.component.styl'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryDetailComponent {
	@Input() isOpened = false;
	@Input() images: Array<{ small: string, big: string }>;
	@Input() index;

	@Output() indexChange = new EventEmitter();
	@Output() close = new EventEmitter();

	config = {
		navigation: {
			nextEl: '.gallery-view__button--next',
			prevEl: '.gallery-view__button--prev',
		},
		preloadImages: false,
		lazy: true
	};
}
