import { Component } from '@angular/core';
import { assetsPath } from '@utils/assets-path';

@Component({
	selector: 'b-photos',
	templateUrl: './photos.component.html',
	styleUrls: ['./photos.component.styl']
})
export class PhotosComponent {
	images = [{
		big: '/assets/gallery/001.jpg',
		small: '/assets/gallery/sm/001_small.jpg',
	}, {
		big: '/assets/gallery/002.jpg',
		small: '/assets/gallery/sm/002_small.jpg',
	}, {
		big: '/assets/gallery/003.jpg',
		small: '/assets/gallery/sm/003_small.jpg',
	}, {
		big: '/assets/gallery/004.jpg',
		small: '/assets/gallery/sm/004_small.jpg',
	}, {
		big: '/assets/gallery/005.jpg',
		small: '/assets/gallery/sm/005_small.jpg',
	}, {
		big: '/assets/gallery/006.jpg',
		small: '/assets/gallery/sm/006_small.jpg',
	}, {
		big: '/assets/gallery/007.jpg',
		small: '/assets/gallery/sm/007_small.jpg',
	}, {
		big: '/assets/gallery/008.jpg',
		small: '/assets/gallery/sm/008_small.jpg',
	}, {
		big: '/assets/gallery/009.jpg',
		small: '/assets/gallery/sm/009_small.jpg',
	}, {
		big: '/assets/gallery/010.jpg',
		small: '/assets/gallery/sm/010_small.jpg',
	}, {
		big: '/assets/gallery/011.jpg',
		small: '/assets/gallery/sm/011_small.jpg',
	}, {
		big: '/assets/gallery/012.jpg',
		small: '/assets/gallery/sm/012_small.jpg',
	}, {
		big: '/assets/gallery/013.jpg',
		small: '/assets/gallery/sm/013_small.jpg',
	}, {
		big: '/assets/gallery/014.jpg',
		small: '/assets/gallery/sm/014_small.jpg',
	}, {
		big: '/assets/gallery/015.jpg',
		small: '/assets/gallery/sm/015_small.jpg',
	}, {
		big: '/assets/gallery/016.jpg',
		small: '/assets/gallery/sm/016_small.jpg',
	}, {
		big: '/assets/gallery/017.jpg',
		small: '/assets/gallery/sm/017_small.jpg',
	}, {
		big: '/assets/gallery/018.jpg',
		small: '/assets/gallery/sm/018_small.jpg',
	}].map(i => ({
		big: assetsPath(i.big),
		small: assetsPath(i.small)
	}));

	index = 0;

	isOpened = false;

	open(index) {
		this.index = index;
		this.isOpened = true;
	}

	close() {
		this.isOpened = false;
	}
}
