import {
	Jumpto,
	BoxBlock,
	CenterBlock,
	Color,
	Link,
	ListBlock,
	Message,
	MessageBlock,
	QuoteBlock, Range,
	Size,
	Smileys,
	SpoilerBlock,
	Styling,
	TextBlock
} from '@models/message.type';

const removeTags = (str: string): string => str.replace(/<\/?[^>]+(>|$)/g, '');

function getIndicesOf(searchStr: string, str: string): Array<number> {
	var searchStrLen = searchStr.length;
	if (searchStrLen == 0) {
		return [];
	}
	var startIndex = 0, index, indices = [];
	while ((index = str.indexOf(searchStr, startIndex)) > -1) {
		indices.push(index);
		startIndex = index + searchStrLen;
	}
	return indices;
}

function escapeRegExp(string) {
	return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function wrapString(str: string, start: number, end: number, tagName: string, attr = {}): string {
	const withoutTags = removeTags(str);

	const textToReplace = withoutTags.substring(start, end);
	const textToReplaceRegExp = new RegExp(escapeRegExp(textToReplace), 'gm')

	const attrKeys = Object.keys(attr);
	const attrString = attrKeys.map(k => ` ${k}="${attr[k]}"`).join('');

	const matchIndex = getIndicesOf(textToReplace, withoutTags).indexOf(start);
	let i = 0;

	if (matchIndex === -1) {
		return str;
	}

	return str.replace(textToReplaceRegExp, (match, offset, ...params) => {
		if (i === matchIndex) {
			i++;
			return `<${tagName}${attrString}>${textToReplace}</${tagName}>`;
		} else {
			i++;
			return textToReplace;
		}
	});
}

const TagNames = {
	'bold': 'b',
	'color': 'span',
	'size': 'span',
	'italic': 'i',
	'underline': 'u',
	'link': 'a',
	'jumpto': 'a',
	'anchor': 'span',
};

const ReplaceColors = {
	'#000000': '#606060'
};

const sortSmileys = (a: Smileys, b: Smileys) => {
	if (a.position === b.position) {
		return a.order > b.order ? 1 : -1;
	}

	return a.position > b.position ? 1 : -1;
}

function insertAtIndex(str: string, index: number, toInsert: string): string {
	return str.slice(0, index) + toInsert + str.slice(index);
}

function addSmileys(str: string, smileys: Array<Smileys>) {
	let newStr = str;
	smileys.sort(sortSmileys);

	smileys.forEach((s, index) => {
		const toInsert = `<img src='${s.value.src}'>`;
		newStr = insertAtIndex(newStr, s.position, toInsert);
		for (let i = index + 1; i < smileys.length; i++) {
			smileys[i].position += toInsert.length;
		}
	});

	return newStr.replace(/:([A-Z0-9]*):/gm, `<img src='https://www.stratege.ru/forums/images/ps3t/smilies/buttons/$1.svg'>`);
}

const getAttributes = (blockName: string, item: Range) => {
	switch (blockName) {
		case 'link':
			return { href: (item as Link).attr, target: '_blank' };
		case 'jumpto':
			return { href: `${location.pathname}#${(item as Jumpto).attr}` };
		case 'anchor':
			return { id: (item as Jumpto).attr };
		case 'color':
			const color = (item as Color).attr;
			return { style: `color: ${color in ReplaceColors ? ReplaceColors[color] : color};` };
		case 'size':
			const size = (item as Size).attr;
			return { class: `size-${size}` };
		default:
			return {};
	}
}

function parseTextBlock(b: TextBlock) {
	let newText = b.text;
	// formatting
	if (b.style) {
		if (b.style['smileys']) {
			newText = addSmileys(newText, b.style['smileys'] as Array<Smileys>);
		}

		Object.keys(TagNames).forEach(k => {
			if (!b.style[k]) {
				return;
			}

			b.style[k].forEach((item: any) => {

				newText = wrapString(newText, item.range.start, item.range.end, TagNames[k], getAttributes(k, item));
			});
		});
	}

	return newText.replace(/\r\n/gm, '<br>');
}

function parseListBlock(b: ListBlock): string {
	const res = b.lines
		.map(l => l.elements
			.map(e => parseTextBlock(e))
			.reduce((acc, val) => acc.concat(val), []))
		.map(l => `<li>${l}</li>`)
		.join('');
	return `<ul>${res}</ul>`;
}

function parseBoxBlock(b: BoxBlock): string {
	switch (b.name) {
		case 'video':
			return `<iframe width='560' height='315' src='https://www.youtube.com/embed/${b.value.match(/(youtu\.be\/|youtube\.com\/(watch\?(.*&)?v=|(embed|v)\/))([^\?&"'>]+)/
			)[5]}' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>`

		case 'image':
			return b.event ? `<a href='${b.event.value}' target='_blank' rel='noopener'><img src='${b.value}' width='${b.size.width}' height='${b.size.height}'></a>` : `<img src='${b.value}' width='${b.size.width}' height='${b.size.height}'>`;
	}
}

function parseSpoilerBlock(block: SpoilerBlock) {
	const content = block.elements.map(parseBlock).join('<br/>');
	return `<details>
		<summary>${block.attr || 'Спойлер'}</summary>
		${content}
	</details>`;
}

function parseQuoteBlock(block: QuoteBlock) {
	const content = block.elements.map(parseBlock).join('<br/>');

	return `<blockquote>${content}</blockquote>`;
}

function parseCenterBlock(block: CenterBlock) {
	const content = block.elements.map(parseBlock).join('<br/>');

	return `<p class='center'>${content}</p>`;
}

function parseBlockBlock(block: SpoilerBlock | QuoteBlock | CenterBlock) {
	switch (block.name) {
		case 'quote':
			return parseQuoteBlock(block);
		case 'spoiler':
			return parseSpoilerBlock(block);
		case 'center':
			return parseCenterBlock(block);
	}
}

function parseBlock(b: MessageBlock) {
	switch (b.type) {
		case 'text':
			return parseTextBlock(b);
		case 'list':
			return parseListBlock(b);
		case 'box':
			return parseBoxBlock(b);
		case 'block':
			return parseBlockBlock(b);
		default:
			return '';
	}
}

export const parse = (msg: Message): string => {
	return msg && msg.map(block => parseBlock(block)).join('<br>');
}
