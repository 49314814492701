import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'b-icon',
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.styl'],
	host: {
		class: 'b-icon'
	},
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent {
	@Input() icon;
	@Input() size = 36;
}
