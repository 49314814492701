import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { WalkthroughService } from '@services/walkthrough.service';
import { Article } from '@models/article.interface';

@Component({
	selector: 'b-walkthrough',
	templateUrl: './walkthrough.component.html',
	styleUrls: ['./walkthrough.component.styl'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WalkthroughComponent {
	article: Article;

	constructor(
		private walkthroughService: WalkthroughService,
		private cdr: ChangeDetectorRef
	) {
		this.walkthroughService.getInfo()
			.subscribe(res => {
				this.article = res;
				this.cdr.markForCheck();
			});
	}
}
