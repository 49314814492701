<div class='row'>
	<a
		href='https://www.playstation.com/ru-ru/explore/ps4/games/'
		target='_blank'
		rel='noopener'
	>
		<img
			[src]="'/assets/ps4-pro-logo.svg' | assetsPath"
			alt=''
		></a>
</div>

<p>
	Все материалы, названия игр, названия и/или внешний вид продукции, товарные знаки, художественные работы и все
	сопутствующие изображения являются товарными знаками и/или объектом собственности соответствующих владельцев. Все
	права сохранены.
</p>
