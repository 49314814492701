import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'b-gallery-preview',
	templateUrl: './gallery-preview.component.html',
	styleUrls: ['./gallery-preview.component.styl'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryPreviewComponent {
	@Input() image: { small: string, big: string };
	@Output() open = new EventEmitter();
}
