<p>Здесь собрано творчество поклонников игры «Одни из нас: Часть II». Вы можете прислать свою работу заполнив специальную форму внизу страницы. Если то или иное изображение было добавлено без вашего ведома и вы не хотите, чтобы оно здесь было, напишите нам.</p><br><br>

<b-gallery-list>
	<b-gallery-preview
		*ngFor='let image of images; let i = index'
		[image]='image'
		(open)='open(i)'
	>
		<a
			[href]='image.link'
			target='_blank'
			rel='noopener noreferrer'
			class='link'
		>
			{{image.username}}<b-icon [icon]='image.icon' size='16'></b-icon>
		</a>
		<div *ngIf='image.isParticipating'>Участник конкурса</div>
	</b-gallery-preview>
</b-gallery-list>

<b-gallery-detail
	[(index)]='index'
	[isOpened]='isOpened'
	[images]='images'
	(close)='close()'
></b-gallery-detail>

<div class='container'>
	Вы&nbsp;можете прислать свою работу заполнив специальную форму

	<a
		href='https://docs.google.com/forms/d/e/1FAIpQLSdpuikhM1LYxiqFEgRTEtIG8pwk57N4FkQbkM9bJYWxutIPlQ/viewform?usp=sf_link'
		target='_blank'
		class='btn'
		rel='noopener'
	>ЗАПОЛНИТЬ ФОРМУ</a>
</div>
