<div
	class='message'
>
	<div class='message__header'>
		<img
			[src]='message.author.avatar'
			alt=''
		>
		<div class='message__header-content'>
			<div class='message__author'>{{message.author.name}}</div>
			<div class='message__date'>{{message.date | date:'dd.MM.yyyy в HH:mm'}}</div>
		</div>
		<div
			class='message__rating'
			[class.message__rating--green]='message.goodrank > 0'
			[class.message__rating--red]='message.goodrank < 0'
		>{{message.goodrank}}</div>
	</div>
	<div class='message__text'>
		<b-message-parser [message]='message.text'></b-message-parser>
	</div>
	<div class='message__children'>
		<b-player-message
			*ngFor='let child of message.children'
			[message]='child'
		></b-player-message>
	</div>
</div>
