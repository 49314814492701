<b-gallery-list>
	<b-gallery-preview
		*ngFor='let image of images; let i = index'
		[image]='image'
		(open)='open(i)'
	></b-gallery-preview>
</b-gallery-list>

<b-gallery-detail
	[(index)]='index'
	[isOpened]='isOpened'
	[images]='images'
	(close)='close()'
></b-gallery-detail>
