import { Component } from '@angular/core';

@Component({
	selector: 'b-art',
	templateUrl: './art.component.html',
	styleUrls: ['./art.component.styl']
})
export class ArtComponent {
	images = [{
		index: '1307-01',
		small: '/assets/art/part2/small/1307-01.jpg',
		big: '/assets/art/part2/1307-01.jpg',
		username: 'Никита Нант',
		link: 'https://www.artstation.com/nikita_nant',
		icon: 'artstation',
		isParticipating: true
	}, {
		index: '1207-06',
		small: '/assets/art/part2/small/1207-06.jpg',
		big: '/assets/art/part2/1207-06.png',
		username: 'Ксения Ким',
		link: 'https://twitter.com/goldkanet',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '1207-05',
		small: '/assets/art/part2/small/1207-05.jpg',
		big: '/assets/art/part2/1207-05.jpg',
		username: 'Юлия',
		link: 'https://twitter.com/disvstcr',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '1207-04',
		small: '/assets/art/part2/small/1207-04.jpg',
		big: '/assets/art/part2/1207-04.png',
		username: 'Мария Петрова',
		link: 'https://www.artstation.com/sawatakibari',
		icon: 'artstation',
		isParticipating: true
	}, {
		index: '1207-03',
		small: '/assets/art/part2/small/1207-03.jpg',
		big: '/assets/art/part2/1207-03.jpg',
		username: 'Березовская Наталья',
		link: 'https://twitter.com/WhiteMarten1',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '1207-02',
		small: '/assets/art/part2/small/1207-02.jpg',
		big: '/assets/art/part2/1207-02.png',
		username: 'Валерия',
		link: 'https://twitter.com/Herak_Durak',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '1207-01',
		small: '/assets/art/part2/small/1207-01.jpg',
		big: '/assets/art/part2/1207-01.jpg',
		username: 'Елена',
		link: 'https://twitter.com/elencoolart',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '1107-06',
		small: '/assets/art/part2/small/1107-06.jpg',
		big: '/assets/art/part2/1107-06.jpg',
		username: 'JapantuninG',
		link: 'https://www.deviantart.com/japantuning',
		icon: 'deviantart',
		isParticipating: true
	}, {
		index: '1107-05',
		small: '/assets/art/part2/small/1107-05.jpg',
		big: '/assets/art/part2/1107-05.png',
		username: 'Лариса',
		link: 'https://twitter.com/yLFpKG5YFrfgoIY',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '1107-04',
		small: '/assets/art/part2/small/1107-04.jpg',
		big: '/assets/art/part2/1107-04.jpg',
		username: 'Insomnia',
		link: 'https://twitter.com/insomniac_li',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '1107-03',
		small: '/assets/art/part2/small/1107-03.jpg',
		big: '/assets/art/part2/1107-03.jpg',
		username: 'Екатерина',
		link: 'https://clampi_lampi.artstation.com/',
		icon: 'artstation',
		isParticipating: true
	}, {
		index: '1107-02',
		small: '/assets/art/part2/small/1107-02.jpg',
		big: '/assets/art/part2/1107-02.jpg',
		username: 'Тимофей Муравьёв',
		link: 'https://www.artstation.com/muravey4ik',
		icon: 'artstation',
		isParticipating: true
	}, {
		index: '1107-01',
		small: '/assets/art/part2/small/1107-01.jpg',
		big: '/assets/art/part2/1107-01.jpg',
		username: 'Arina Ladygina',
		link: 'https://twitter.com/ari_s_lad',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '1007-07',
		small: '/assets/art/part2/small/1007-07.jpg',
		big: '/assets/art/part2/1007-07.png',
		username: 'Diana Kresge',
		link: 'https://twitter.com/dk_draws',
		icon: 'twitter',
		isParticipating: false
	}, {
		index: '0607-03',
		small: '/assets/art/part2/small/0607-03.jpg',
		big: '/assets/art/part2/0607-03.jpg',
		username: 'Diana Kresge',
		link: 'https://twitter.com/dk_draws',
		icon: 'twitter',
		isParticipating: false
	}, {
		index: '1004-01',
		small: '/assets/art/part2/small/1004-01.jpg',
		big: '/assets/art/part2/1004-01.jpg',
		username: 'Diana Kresge',
		link: 'https://twitter.com/dk_draws',
		icon: 'twitter',
		isParticipating: false
	}, {
		index: '1007-05',
		small: '/assets/art/part2/small/1007-05.jpg',
		big: '/assets/art/part2/1007-05.jpg',
		username: 'Marcia Effect',
		link: 'https://twitter.com/trostes',
		icon: 'twitter',
		isParticipating: false
	}, {
		index: '2906-01',
		small: '/assets/art/part2/small/2906-01.jpg',
		big: '/assets/art/part2/2906-01.jpg',
		username: 'Marcia Effect',
		link: 'https://twitter.com/trostes',
		icon: 'twitter',
		isParticipating: false
	}, {
		index: '2906-02',
		small: '/assets/art/part2/small/2906-02.jpg',
		big: '/assets/art/part2/2906-02.jpg',
		username: 'Marcia Effect',
		link: 'https://twitter.com/trostes',
		icon: 'twitter',
		isParticipating: false
	}, {
		index: '1007-04',
		small: '/assets/art/part2/small/1007-04.jpg',
		big: '/assets/art/part2/1007-04.jpg',
		username: 'Julia Zhuravleva',
		link: 'https://twitter.com/eleth_art',
		icon: 'twitter',
		isParticipating: false
	}, {
		index: '1007-03',
		small: '/assets/art/part2/small/1007-03.jpg',
		big: '/assets/art/part2/1007-03.jpg',
		username: 'Вадим',
		link: 'https://twitter.com/chekalov32',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '1007-02',
		small: '/assets/art/part2/small/1007-02.jpg',
		big: '/assets/art/part2/1007-02.jpg',
		username: 'Вадим',
		link: 'https://twitter.com/Bl4ckF1r3_1337',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '1007-01',
		small: '/assets/art/part2/small/1007-01.jpg',
		big: '/assets/art/part2/1007-01.jpg',
		username: '𝙘𝙖𝙢𝙞𝙡𝙡𝙖',
		link: 'https://twitter.com/bigwolfart',
		icon: 'twitter',
		isParticipating: false
	}, {
		index: '0807-01',
		small: '/assets/art/part2/small/0807-01.jpg',
		big: '/assets/art/part2/0807-01.jpg',
		username: '𝙘𝙖𝙢𝙞𝙡𝙡𝙖',
		link: 'https://twitter.com/bigwolfart',
		icon: 'twitter',
		isParticipating: false
	}, {
		index: '0407-05',
		small: '/assets/art/part2/small/0407-05.jpg',
		big: '/assets/art/part2/0407-05.jpg',
		username: '𝙘𝙖𝙢𝙞𝙡𝙡𝙖',
		link: 'https://twitter.com/bigwolfart',
		icon: 'twitter',
		isParticipating: false
	}, {
		index: '0907-09',
		small: '/assets/art/part2/small/0907-09.jpg',
		big: '/assets/art/part2/0907-09.jpg',
		username: 'Зарина',
		link: 'https://twitter.com/9QEdzakOG11s9YY',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '0907-08',
		small: '/assets/art/part2/small/0907-08.jpg',
		big: '/assets/art/part2/0907-08.jpg',
		username: 'Дзерасса Савлаева',
		link: 'https://www.artstation.com/lgera512',
		icon: 'artstation',
		isParticipating: true
	}, {
		index: '0907-07',
		small: '/assets/art/part2/small/0907-07.jpg',
		big: '/assets/art/part2/0907-07.png',
		username: 'Ермак',
		link: 'https://twitter.com/ErmakLitvinenko',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '0907-06',
		small: '/assets/art/part2/small/0907-06.jpg',
		big: '/assets/art/part2/0907-06.jpg',
		username: '𝕍𝕖𝕝𝕚𝕜𝕚𝕚 𝕃𝕠𝕤𝕠𝕤𝕚𝕜',
		link: 'https://twitter.com/VLososik',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '0907-05',
		small: '/assets/art/part2/small/0907-05.jpg',
		big: '/assets/art/part2/0907-05.jpg',
		username: 'Zoe',
		link: 'https://twitter.com/elimnebe',
		icon: 'twitter',
		isParticipating: false
	}, {
		index: '0307-01',
		small: '/assets/art/part2/small/0307-01_1.jpg',
		big: '/assets/art/part2/0307-01.jpg',
		username: 'Zoe',
		link: 'https://twitter.com/elimnebe',
		icon: 'twitter',
		isParticipating: false
	}, {
		index: '0907-01',
		small: '/assets/art/part2/small/0907-01.jpg',
		big: '/assets/art/part2/0907-01.jpg',
		username: 'prawnm33',
		link: 'https://twitter.com/mmmagnolia',
		icon: 'twitter',
		isParticipating: false
	}, {
		index: '0907-02',
		small: '/assets/art/part2/small/0907-02.jpg',
		big: '/assets/art/part2/0907-02.jpg',
		username: 'prawnm33',
		link: 'https://twitter.com/mmmagnolia',
		icon: 'twitter',
		isParticipating: false
	}, {
		index: '0807-02',
		small: '/assets/art/part2/small/0807-02.jpg',
		big: '/assets/art/part2/0807-02.jpg',
		username: 'Евгения',
		link: 'https://www.artstation.com/prodocline',
		icon: 'artstation',
		isParticipating: true
	}, {
		index: '0607-02',
		small: '/assets/art/part2/small/0607-02.jpg',
		big: '/assets/art/part2/0607-02.jpg',
		username: 'Андрей',
		link: 'https://twitter.com/Paatddal',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '0607-01',
		small: '/assets/art/part2/small/0607-01.jpg',
		big: '/assets/art/part2/0607-01.tif',
		username: 'Анатолий',
		link: 'https://twitter.com/X_Anatoly',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '0507-03',
		small: '/assets/art/part2/small/0507-03.jpg',
		big: '/assets/art/part2/0507-03.png',
		username: 'Покатович Е.В.',
		link: 'https://twitter.com/punisher991111',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '0507-02',
		small: '/assets/art/part2/small/0507-02.jpg',
		big: '/assets/art/part2/0507-02.jpg',
		username: 'Виктория Кривко',
		link: 'https://www.artstation.com/kaya_malone',
		icon: 'artstation',
		isParticipating: true
	}, {
		index: '0507-01',
		small: '/assets/art/part2/small/0507-01.jpg',
		big: '/assets/art/part2/0507-01.jpg',
		username: 'Ромэо',
		link: 'https://twitter.com/Rromeo66084279',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '0407-06',
		small: '/assets/art/part2/small/0407-06.jpg',
		big: '/assets/art/part2/0407-06.jpg',
		username: 'Oskunk',
		link: 'https://twitter.com/Oskunk',
		icon: 'twitter',
		isParticipating: false
	}, {
		index: '0407-04',
		small: '/assets/art/part2/small/0407-04.jpg',
		big: '/assets/art/part2/0407-04.jpg',
		username: 'Арина',
		link: 'https://twitter.com/Ayrissha',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '0407-03',
		small: '/assets/art/part2/small/0407-03.jpg',
		big: '/assets/art/part2/0407-03.jpg',
		username: 'Анжелика',
		link: 'https://mobile.twitter.com/fox666999',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '0407-01',
		small: '/assets/art/part2/small/0407-01.jpg',
		big: '/assets/art/part2/0407-01.jpg',
		username: 'Илья Шангин',
		link: 'https://www.deviantart.com/heroofkonoha',
		icon: 'deviantart',
		isParticipating: true
	}, {
		index: '0307-01',
		small: '/assets/art/part2/small/0307-01.jpg',
		big: '/assets/art/part2/0307-01.gif',
		username: '6VCR',
		link: 'https://twitter.com/sixveeceear',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '0207-01',
		small: '/assets/art/part2/small/0207-01.jpg',
		big: '/assets/art/part2/0207-01.jpg',
		username: 'Ollie Hoff',
		link: 'https://twitter.com/OllieHoff',
		icon: 'twitter',
		isParticipating: false
	}, {
		index: '001',
		small: '/assets/art/part1/small/001.jpg',
		big: '/assets/art/part1/001.jpg',
		username: 'маsнё',
		link: 'https://twitter.com/Marceline2174',
		icon: 'twitter'
	}, {
		index: '002',
		small: '/assets/art/part1/small/002.jpg',
		big: '/assets/art/part1/002.jpg',
		username: 'маsнё',
		link: 'https://twitter.com/Marceline2174',
		icon: 'twitter'
	}, {
		index: '003',
		small: '/assets/art/part1/small/003.jpg',
		big: '/assets/art/part1/003.jpg',
		username: 'маsнё',
		link: 'https://twitter.com/Marceline2174',
		icon: 'twitter'
	}, {
		index: '004',
		small: '/assets/art/part1/small/004.jpg',
		big: '/assets/art/part1/004.jpg',
		username: 'маsнё',
		link: 'https://twitter.com/Marceline2174',
		icon: 'twitter'
	}, {
		index: '005',
		small: '/assets/art/part1/small/005.jpg',
		big: '/assets/art/part1/005.jpg',
		username: 'маsнё',
		link: 'https://twitter.com/Marceline2174',
		icon: 'twitter'
	}, {
		index: '006',
		small: '/assets/art/part1/small/006.jpg',
		big: '/assets/art/part1/006.jpg',
		username: 'маsнё',
		link: 'https://twitter.com/Marceline2174',
		icon: 'twitter'
	}, {
		index: '007',
		small: '/assets/art/part1/small/007.jpg',
		big: '/assets/art/part1/007.jpg',
		username: 'маsнё',
		link: 'https://twitter.com/Marceline2174',
		icon: 'twitter'
	}, {
		index: '008',
		small: '/assets/art/part1/small/008.jpg',
		big: '/assets/art/part1/008.jpg',
		username: 'маsнё',
		link: 'https://twitter.com/Marceline2174',
		icon: 'twitter'
	}, {
		index: '009',
		small: '/assets/art/part1/small/009.jpg',
		big: '/assets/art/part1/009.jpg',
		username: 'маsнё',
		link: 'https://twitter.com/Marceline2174',
		icon: 'twitter'
	}, {
		index: '010',
		small: '/assets/art/part1/small/010.jpg',
		big: '/assets/art/part1/010.jpg',
		username: 'laris',
		link: 'https://twitter.com/sapphiciri',
		icon: 'twitter'
	}, {
		index: '011',
		small: '/assets/art/part1/small/011.jpg',
		big: '/assets/art/part1/011.jpg',
		username: 'laris',
		link: 'https://twitter.com/sapphiciri',
		icon: 'twitter'
	}, {
		index: '012',
		small: '/assets/art/part1/small/012.jpg',
		big: '/assets/art/part1/012.jpg',
		username: 'laris',
		link: 'https://twitter.com/sapphiciri',
		icon: 'twitter'
	}, {
		index: '013',
		small: '/assets/art/part1/small/013.jpg',
		big: '/assets/art/part1/013.jpg',
		username: 'bea',
		link: 'https://twitter.com/zzombias',
		icon: 'twitter'
	}, {
		index: '013A',
		small: '/assets/art/part1/small/013A.jpg',
		big: '/assets/art/part1/013A.jpg',
		username: 'Денис',
		link: 'https://www.artstation.com/tokuror',
		icon: 'artstation',
		isParticipating: true
	}, {
		index: '013B',
		small: '/assets/art/part1/small/013B.jpg',
		big: '/assets/art/part1/013B.jpg',
		username: 'Ирина Садовникова',
		link: 'https://twitter.com/Sadovnikova_Ira',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '014',
		small: '/assets/art/part1/small/014.jpg',
		big: '/assets/art/part1/014.jpg',
		username: '𝕯𝖗𝖆𝖈𝖚𝖑𝖔𝖛𝖊',
		link: 'https://twitter.com/Be_Someone_Else',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '015',
		small: '/assets/art/part1/small/015.jpg',
		big: '/assets/art/part1/015.jpg',
		username: 'Jessie Lo',
		link: 'https://twitter.com/JessieLoRocks',
		icon: 'twitter'
	}, {
		index: '016',
		small: '/assets/art/part1/small/016.jpg',
		big: '/assets/art/part1/016.jpg',
		username: 'Xiao Tong',
		link: 'https://twitter.com/Velinxi',
		icon: 'twitter'
	}, {
		index: '017',
		small: '/assets/art/part1/small/017.jpg',
		big: '/assets/art/part1/017.png',
		username: 'Xiao Tong',
		link: 'https://twitter.com/Velinxi',
		icon: 'twitter'
	}, {
		index: '018',
		small: '/assets/art/part1/small/018.jpg',
		big: '/assets/art/part1/018.png',
		username: 'Xiao Tong',
		link: 'https://twitter.com/Velinxi',
		icon: 'twitter'
	}, {
		index: '018A',
		small: '/assets/art/part1/small/018A.jpg',
		big: '/assets/art/part1/018A.jpg',
		username: 'Костя',
		link: 'https://twitter.com/Winged_Rocket',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '019',
		small: '/assets/art/part1/small/019.jpg',
		big: '/assets/art/part1/019.jpg',
		username: 'ﾊﾏﾁ',
		link: 'https://twitter.com/gyohmc',
		icon: 'twitter'
	}, {
		index: '020',
		small: '/assets/art/part1/small/020.jpg',
		big: '/assets/art/part1/020.jpg',
		username: 'Caio Santos',
		link: 'https://twitter.com/BlackSalander',
		icon: 'twitter'
	}, {
		index: '021',
		small: '/assets/art/part1/small/021.jpg',
		big: '/assets/art/part1/021.jpg',
		username: 'Caio Santos',
		link: 'https://twitter.com/BlackSalander',
		icon: 'twitter'
	}, {
		index: '022',
		small: '/assets/art/part1/small/022.jpg',
		big: '/assets/art/part1/022.jpg',
		username: 'Caio Santos',
		link: 'https://twitter.com/BlackSalander',
		icon: 'twitter'
	}, {
		index: '022A',
		small: '/assets/art/part1/small/022A.jpg',
		big: '/assets/art/part1/022A.jpg',
		username: 'Алина Леонова',
		link: 'https://twitter.com/dx3Brzv52HViEZa',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '022B',
		small: '/assets/art/part1/small/022B.jpg',
		big: '/assets/art/part1/022B.jpg',
		username: 'Владислав',
		link: 'https://twitter.com/vladtops',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '022C',
		small: '/assets/art/part1/small/022C.jpg',
		big: '/assets/art/part1/022C.jpg',
		username: 'Сергей',
		link: 'https://twitter.com/e9jtrNGvOIukWSS',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '023',
		small: '/assets/art/part1/small/023.jpg',
		big: '/assets/art/part1/023.jpg',
		username: 'Madibek Musabekov',
		link: 'https://twitter.com/m_mykosch/',
		icon: 'twitter'
	}, {
		index: '024',
		small: '/assets/art/part1/small/024.jpg',
		big: '/assets/art/part1/024.jpg',
		username: 'Madibek Musabekov',
		link: 'https://twitter.com/m_mykosch/',
		icon: 'twitter'
	}, {
		index: '024A',
		small: '/assets/art/part1/small/024A.jpg',
		big: '/assets/art/part1/024A.jpg',
		username: 'Наталья',
		link: 'https://www.artstation.com/chiyosun',
		icon: 'artstation',
		isParticipating: true
	}, {
		index: '024B',
		small: '/assets/art/part1/small/024B.jpg',
		big: '/assets/art/part1/024B.jpg',
		username: 'Максим',
		link: 'https://twitter.com/Cra33y',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '025',
		small: '/assets/art/part1/small/025.jpg',
		big: '/assets/art/part1/025.jpg',
		username: 'azra 2.0',
		link: 'https://twitter.com/papirfecni',
		icon: 'twitter'
	}, {
		index: '026',
		small: '/assets/art/part1/small/026.jpg',
		big: '/assets/art/part1/026.jpg',
		username: 'azra 2.0',
		link: 'https://twitter.com/papirfecni',
		icon: 'twitter'
	}, {
		index: '027',
		small: '/assets/art/part1/small/027.jpg',
		big: '/assets/art/part1/027.jpg',
		username: 'goldkanet',
		link: 'https://twitter.com/goldkanet',
		icon: 'twitter'
	}, {
		index: '028',
		small: '/assets/art/part1/small/028.jpg',
		big: '/assets/art/part1/028.jpg',
		username: 'Flatcetera',
		link: 'https://twitter.com/Flatceteraart',
		icon: 'twitter'
	}, {
		index: '029',
		small: '/assets/art/part1/small/029.jpg',
		big: '/assets/art/part1/029.jpg',
		username: 'Flatcetera',
		link: 'https://twitter.com/Flatceteraart',
		icon: 'twitter'
	}, {
		index: '030',
		small: '/assets/art/part1/small/030.jpg',
		big: '/assets/art/part1/030.jpg',
		username: 'Flatcetera',
		link: 'https://twitter.com/Flatceteraart',
		icon: 'twitter'
	}, {
		index: '031',
		small: '/assets/art/part1/small/031.jpg',
		big: '/assets/art/part1/031.jpg',
		username: 'Flatcetera',
		link: 'https://twitter.com/Flatceteraart',
		icon: 'twitter'
	}, {
		index: '032',
		small: '/assets/art/part1/small/032.jpg',
		big: '/assets/art/part1/032.jpg',
		username: 'пэточка',
		link: 'https://twitter.com/mentikme',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '033',
		small: '/assets/art/part1/small/033.jpg',
		big: '/assets/art/part1/033.jpg',
		username: 'соевое мясо',
		link: 'https://twitter.com/M4so4',
		icon: 'twitter'
	}, {
		index: '034',
		small: '/assets/art/part1/small/034.jpg',
		big: '/assets/art/part1/034.jpg',
		username: 'wamaii',
		link: 'https://twitter.com/Wamee_',
		icon: 'twitter'
	}, {
		index: '035',
		small: '/assets/art/part1/small/035.jpg',
		big: '/assets/art/part1/035.jpg',
		username: 'Fernanda Suarez',
		link: 'https://twitter.com/fdasuarezart',
		icon: 'twitter'
	}, {
		index: '036',
		small: '/assets/art/part1/small/036.jpg',
		big: '/assets/art/part1/036.jpg',
		username: 'риня',
		link: 'https://twitter.com/bestsenpie',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '036A',
		small: '/assets/art/part1/small/036A.jpg',
		big: '/assets/art/part1/036A.jpg',
		username: 'Михаил',
		link: 'https://mobile.twitter.com/',
		icon: 'twitter',
		isParticipating: true
	}, {
		index: '037',
		small: '/assets/art/part1/small/037.jpg',
		big: '/assets/art/part1/037.jpg',
		username: 'Paulo Gazola',
		link: 'https://twitter.com/Gazo1a',
		icon: 'twitter'
	}, {
		index: '038',
		small: '/assets/art/part1/small/038.png',
		big: '/assets/art/part1/038.png',
		username: 'Julian',
		link: 'https://twitter.com/theartistjulian',
		icon: 'twitter'
	}, {
		index: '039',
		small: '/assets/art/part1/small/039.jpg',
		big: '/assets/art/part1/039.jpg',
		username: 'Enise',
		link: 'https://twitter.com/enisestudio',
		icon: 'twitter'
	}, {
		index: '040',
		small: '/assets/art/part1/small/040.jpg',
		big: '/assets/art/part1/040.jpg',
		username: 'Joel DuQue',
		link: 'https://twitter.com/ceruleanraven',
		icon: 'twitter'
	}, {
		index: '041',
		small: '/assets/art/part1/small/041.jpg',
		big: '/assets/art/part1/041.jpg',
		username: '山本',
		link: 'https://twitter.com/sahoobb/',
		icon: 'twitter'
	}, {
		index: '042',
		small: '/assets/art/part1/small/042.jpg',
		big: '/assets/art/part1/042.jpg',
		username: 'RUIZ BURGOS',
		link: 'https://twitter.com/RuizBurgosArt',
		icon: 'twitter'
	}, {
		index: '043',
		small: '/assets/art/part1/small/043.jpg',
		big: '/assets/art/part1/043.jpg',
		username: 'badmiaou',
		link: 'https://twitter.com/badmiaou',
		icon: 'twitter'
	}];

	index = 0;

	isOpened = false;

	open(index) {
		this.index = index;
		this.isOpened = true;
	}

	close() {
		this.isOpened = false;
	}
}
