import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'b-gallery-list',
	templateUrl: './gallery-list.component.html',
	styleUrls: ['./gallery-list.component.styl'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryListComponent {
}
