import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Article } from '@models/article.interface';
import { map } from 'rxjs/operators';

const removeFirstPic = (article: Article): Article => {
	const [image, ...otherBlocks] = article.text;
	return ({
		...article,
		text: otherBlocks
	});
}

@Injectable({
	providedIn: 'root'
})
export class WalkthroughService {
	constructor(private http: HttpClient) {
	}

	getInfo(): Observable<Article> {
		return this.http.get<Article>('https://app.stratege.ru/articles/article/99748')
			.pipe(map(removeFirstPic));
	}
}
