export interface Scene {
	scene: string;
	text: string;
	hideTitle?: boolean;
	buttons: Array<{
		to: string;
		text: string;
	}>
}

export const Data: Array<Scene> = [{
	scene: 'hello',
	text: `Приветствуем вас в&nbsp;интерактивном текстовом приключении &laquo;Один из&nbsp;них&raquo;, действие которого разворачивается в&nbsp;мире игровой вселенной &laquo;Одни из&nbsp;нас&raquo;, созданной компаниями Naughty Dog и&nbsp;Sony Interactive Entertainment.
	
	История, готовая вот-вот раскрыться перед вами, не&nbsp;имеет связи с&nbsp;оригинальными сюжетными линиями игр &laquo;Одни из&nbsp;нас&raquo;, &laquo;Одни из&nbsp;нас: Оставленные позади&raquo; и&nbsp;&laquo;Одни из&nbsp;нас: Часть II&raquo;. Она является независимым творческим произведением, созданным в&nbsp;развлекательных целях.`,
	buttons: [{
		to: '0',
		text: 'Начать игру',
	}],
	hideTitle: true
}, {
	scene: '0',
	text: `Коммуна Эмигрейшн Оакс. Май 2037&nbsp;года.

Патруль обнаружил человека в&nbsp;изорванной одежде. По&nbsp;состоянию было видно, что он&nbsp;несколько дней блуждал без еды и&nbsp;воды по&nbsp;лесу. Из-за многочисленных ранений и&nbsp;истощения он&nbsp;потерял сознание ещё до&nbsp;того, как у&nbsp;него успели что-либо узнать. Патрульные приняли решение забрать его в&nbsp;поселение, где мужчина пролежал почти неделю без сознания. На&nbsp;нём уже было поставили крест, но&nbsp;неожиданно человек открыл глаза и&nbsp;окинул непонимающим взором комнату. Сидевший неподалёку охранник встрепенулся и&nbsp;позвал нашедших таинственного мужчину патрульных, отдыхавших в&nbsp;том&nbsp;же бараке после очередной смены,&nbsp;&mdash; Сола и&nbsp;Ким. Через несколько минут в&nbsp;дверном проёме появился высокий темнокожий мужчина&nbsp;и, немного пригнувшись, вошёл в&nbsp;комнату. Вслед за&nbsp;ним осторожными шагами зашла кажущаяся хрупкой на&nbsp;фоне здоровяка девушка с&nbsp;собранными в&nbsp;конский хвост волосами.

&mdash;&nbsp;Ну, привет, мы&nbsp;уже место для ямы присматривать начали, а&nbsp;ты&nbsp;очнулся. Будем знакомиться? Я&nbsp;Сол, местный начальник патруля. Это Ким, моя заместительница и&nbsp;главный тактик сего скромного лагеря. А&nbsp;кто&nbsp;ты? Как оказался в&nbsp;лесу?
&mdash;&nbsp;Я... Кто я? И&nbsp;где?
&mdash;&nbsp;Может, ещё спросишь, когда?
&mdash;&nbsp;Ким, не&nbsp;время для твоих шуточек, киноманьячка. Парень, что ты&nbsp;помнишь?
&mdash;&nbsp;Помню... я&nbsp;помню, что мир погиб от&nbsp;кордицепса много лет назад, и&nbsp;что мне пришлось воевать против зомби и&nbsp;других людей. Но&nbsp;где это было, почему и&nbsp;с&nbsp;кем&nbsp;&mdash; не&nbsp;знаю.
&mdash;&nbsp;А&nbsp;звать-то тебя как? И&nbsp;что это за&nbsp;татуировка на&nbsp;твоём предплечье?

Парень опустил глаза и&nbsp;увидел выбитую синими чернилами наколку &laquo;Int#000&raquo;. Под ней был большой шрам и&nbsp;несколько чернильных штрихов, будто там когда-то находилась другая, но&nbsp;была грубо стёрта или выжжена вместе с&nbsp;кожей. Всё, что удалось разглядеть&nbsp;&mdash; две небольшие полосы внизу шрама и&nbsp;что-то похожее на&nbsp;скобу справа от&nbsp;него.

&mdash;&nbsp;Не&nbsp;знаю. Ничего больше не&nbsp;знаю...

Несколько дней с&nbsp;Зеро, такое имя получил гость лагеря, работала главный повар коммуны. Ее&nbsp;звали Анна, еще при той, другой и&nbsp;нормальной жизни она получила медицинское образование в&nbsp;области психиатрии. Но&nbsp;все ее&nbsp;попытки вернуть больному память оказались тщетными. Не&nbsp;помог даже кустарный гипноз, а&nbsp;для медикаментозного или электрического стимулирования мозга не&nbsp;было ресурса. Анна заключила: умственные способности Зеро не&nbsp;пострадали, но&nbsp;добраться до&nbsp;воспоминаний не&nbsp;представляется возможным. Остаётся надеяться лишь на&nbsp;то, что память вернётся со&nbsp;временем, либо ассоциативно.

Последнее понравилось Солу, и&nbsp;он&nbsp;предложил взять Зеро под своё крыло в&nbsp;патруль.

&mdash;&nbsp;Парень ты&nbsp;наверняка опытный, предлагаю проверить твои навыки через несколько дней на&nbsp;нашем импровизированном полигоне. Если пройдёшь&nbsp;&mdash; будешь ходить под присмотром в&nbsp;группе со&nbsp;мной и&nbsp;Ким. Раз сам говоришь, что помнишь войну, может, именно активность такого рода сумеет раскочегарить твою голову. Не&nbsp;пройдёшь испытание&nbsp;&mdash; Анне всегда пригодятся руки для чистки картошки. 

Прошло несколько недель, за&nbsp;которые случались боевые стычки с&nbsp;блуждающими в&nbsp;окрестностях коммуны зомби. Пусть долговременная память у&nbsp;Зеро отказала, двигательная, словесно-логическая, эмоциональная и&nbsp;оперативная
функционировали превосходно. Он&nbsp;помнил, как драться и&nbsp;применять оружие, на&nbsp;лету схватывал тактические наставления Ким и&nbsp;проявлял смекалку. Казалось, у&nbsp;мужчины началась новая жизнь, но&nbsp;жестокая судьба уготовила ему новый поворот...`,
	buttons: [{
		to: '1',
		text: 'Продолжить',
	}]
}, {
	scene: '1',
	text: `Здоровская погодка выдалась. Сегодня мы&nbsp;с&nbsp;Солом и&nbsp;Ким вышли в&nbsp;плановый патруль. Коммуна Эмигрейшен Оакс постоянно организует осмотр окрестностей в&nbsp;целях предотвращения нападений, обычно не&nbsp;уходя за&nbsp;пределы семикилометрового радиуса. На&nbsp;утренней жеребьёвке нам выпала тропа до&nbsp;Солт-Лейк-Сити. Мы&nbsp;выдвинулись из&nbsp;лагеря и&nbsp;прошли несколько километров неспешным шагом, но&nbsp;лес отказывался заканчиваться.

&mdash;&nbsp;А&nbsp;помните, как 4&nbsp;года назад в&nbsp;этом городе какой-то контрабандист устроил бойню из-за девчонки. Ты&nbsp;с&nbsp;нами недавно, Зеро, может, и&nbsp;не&nbsp;знаешь. Если кратко&nbsp;&mdash; тогда ещё активные &laquo;Цикады&raquo; перехватили не&nbsp;то&nbsp;отца с&nbsp;дочерью, не&nbsp;то... чёрт знает. Словом, у&nbsp;них там, по&nbsp;слухам, была лаборатория, где пытались найти лекарство от&nbsp;кордицепса...

Слова его прервала пара коротких очередей, словно кто-то быстро ломал ветки. Сол сразу определил направление и&nbsp;сказал:

&mdash;&nbsp;Дальше по&nbsp;курсу, расстояние около километра, может, с&nbsp;небольшим. 
&mdash;&nbsp;Что будем делать? &mdash;&nbsp;спросила Ким.

Оба посмотрели на&nbsp;меня, хотя были явно и&nbsp;опытнее, и&nbsp;старше. Видимо, просто хотели дать мне почувствовать себя равным.`,
	buttons: [{
		to: '2',
		text: 'Давайте вернёмся и&nbsp;поднимем тревогу',
	}, {
		to: '1A',
		text: 'Продолжим путь и&nbsp;выясним',
	}]
}, {
	scene: '1A',
	text: `&mdash;&nbsp;Предлагаю продолжить путь и&nbsp;посмотреть, что там за&nbsp;стрельба, а&nbsp;наш лагерь достаточно хорошо укреплён. 
&mdash;&nbsp;Сдурел? &mdash;&nbsp;возразил Сол. &mdash;&nbsp;У&nbsp;меня там жена и&nbsp;ребёнок, а&nbsp;здесь за&nbsp;последние несколько лет не&nbsp;было никого из&nbsp;посторонних с&nbsp;оружием. В&nbsp;городе стрельба была, но&nbsp;так близко никто ещё не&nbsp;подбирался. Надо однозначно возвращаться.
&mdash;&nbsp;Поддерживаю Сола. Зеро, не&nbsp;время для геройства, лучше поберечься, чем потом жалеть. 
&mdash;&nbsp;...`,
	buttons: [{
		to: '2A',
		text: 'Согласиться с&nbsp;друзьями и&nbsp;вернуться в&nbsp;лагерь',
	}]
}, {
	scene: '2',
	text: `Моё предложение вернуться в&nbsp;город за&nbsp;помощью, а&nbsp;не&nbsp;вступать в&nbsp;бой, поддержали единогласно, и&nbsp;мы&nbsp;рванули обратно, а&nbsp;Ким на&nbsp;бегу сорвала &laquo;воки-токи&raquo;, пытаясь вызвать нашего связиста. Но&nbsp;эти радиостанции очень слабенькие, дальше километра только по&nbsp;ровной местности и&nbsp;без препятствий ловят сигнал. Что нашли, так сказать, тем и&nbsp;пользуемся. 

Добравшись до&nbsp;коммуны, мы&nbsp;заметили, что начальник караула Майлз стоит неподалёку у&nbsp;ворот с&nbsp;озабоченным видом. Подбежав, Сол рассказал ему об&nbsp;услышанных выстрелах, которые, к&nbsp;тому&nbsp;же, прозвучали ещё несколько раз, пока мы&nbsp;бежали. 

&mdash;&nbsp;Примерно 15&nbsp;минут назад прибыл другой патруль. Они сообщили, что увидели с&nbsp;холмов клубы пыли, сквозь которые проступали силуэты автомобилей. Мы&nbsp;думаем, что они скоро будут здесь! Единственная дорога для транспорта ведёт прямиком сюда.
&mdash;&nbsp;Думаешь, они нападут на&nbsp;нас, Майлз? &mdash;&nbsp;лицо Ким выражало сомнение.
&mdash;&nbsp;Не&nbsp;уверен, но&nbsp;стоит приготовиться к&nbsp;атаке. Закроем ворота, кто-то сядет со&nbsp;снайперской винтовкой на&nbsp;вышке, ещё несколько&nbsp;&mdash; займут прикрывающие позиции на&nbsp;уровне земли. Основные силы встанут за&nbsp;воротами. 

Нам хватило 20&nbsp;минут, чтобы подготовиться и&nbsp;закрыть ворота. Майлз распределял людей. Сол и&nbsp;Ким вызвались пойти на&nbsp;передок, к&nbsp;воротам, но&nbsp;оставалось ещё одно место на&nbsp;крепкой наблюдательной вышке, самом высоком месте нашего лагеря. Она создавалась и&nbsp;для наблюдения, и&nbsp;для снайперского прикрытия, выполняя функции &laquo;гнезда&raquo;. 

&mdash;&nbsp;Зеро! Винтовка с&nbsp;оптикой у&nbsp;нас всего одна, твои навыки стрельбы могут здорово пригодиться. Хочешь пойти на&nbsp;вышку или оставишь это дело мне?`,
	buttons: [{
		to: '4',
		text: 'Занять место на&nbsp;вышке',
	}, {
		to: '3',
		text: 'Присоединиться к&nbsp;Солу и&nbsp;Ким у&nbsp;ворот',
	}]
}, {
	scene: '2A',
	text: `Делать нечего, я&nbsp;не&nbsp;пойду в&nbsp;разведку сам, а&nbsp;Сола очень легко понять. Мы&nbsp;рванули обратно, а&nbsp;Ким на&nbsp;бегу сорвала &laquo;воки-токи&raquo;, пытаясь вызвать нашего связиста. Но&nbsp;эти радиостанции очень слабенькие, дальше километра только по&nbsp;ровной местности и&nbsp;без препятствий ловят сигнал. Что нашли, так сказать, тем и&nbsp;пользуемся. 

Добравшись до&nbsp;коммуны, мы&nbsp;заметили, что начальник караула Майлз стоит неподалёку у&nbsp;ворот с&nbsp;озабоченным видом. Подбежав, Сол рассказал ему об&nbsp;услышанных выстрелах, которые, к&nbsp;тому&nbsp;же, прозвучали ещё несколько раз, пока мы&nbsp;бежали. 

&mdash;&nbsp;Примерно 15&nbsp;минут назад прибыл другой патруль. Они сообщили, что увидели с&nbsp;холмов клубы пыли, сквозь которые проступали силуэты автомобилей. Мы&nbsp;думаем, что они скоро будут здесь! Единственная дорога для транспорта ведёт прямиком сюда.
&mdash;&nbsp;Думаешь, они нападут на&nbsp;нас, Майлз? &mdash;&nbsp;лицо Ким выражало сомнение.
&mdash;&nbsp;Не&nbsp;уверен, но&nbsp;стоит приготовиться к&nbsp;атаке. Закроем ворота, кто-то сядет со&nbsp;снайперской винтовкой на&nbsp;вышке, ещё несколько&nbsp;&mdash; займут прикрывающие позиции на&nbsp;уровне земли. Основные силы встанут за&nbsp;воротами. 

Нам хватило 20&nbsp;минут, чтобы подготовиться и&nbsp;закрыть ворота. Майлз распределял людей. Сол и&nbsp;Ким вызвались пойти на&nbsp;передок, к&nbsp;воротам, но&nbsp;оставалось ещё одно место на&nbsp;крепкой наблюдательной вышке, самом высоком месте нашего лагеря. Она создавалась и&nbsp;для наблюдения, и&nbsp;для снайперского прикрытия, выполняя функции &laquo;гнезда&raquo;. 

&mdash;&nbsp;Зеро! Винтовка с&nbsp;оптикой у&nbsp;нас всего одна, твои навыки стрельбы могут здорово пригодиться. Хочешь пойти на&nbsp;вышку или оставишь это дело мне?`,
	buttons: [{
		to: '4',
		text: 'Занять место на&nbsp;вышке',
	}, {
		to: '3',
		text: 'Присоединиться к&nbsp;Солу и&nbsp;Ким у&nbsp;ворот',
	}]
}, {
	scene: '3',
	text: `Сол и&nbsp;Ким немного потеснились, уступая мне место возле коробок. Я&nbsp;не&nbsp;стал терять время: проверил свою винтовку, перезарядил пистолет и&nbsp;отдал единственную гранату отряду справа. У&nbsp;одного из&nbsp;бойцов был только пистолет, а&nbsp;по&nbsp;его трясущимся рукам стало понятно, что патронов у&nbsp;него мало.

Мы&nbsp;застыли в&nbsp;ожидании, прислушиваясь к&nbsp;отдалённому шуму моторов. Майлз предупредил нас, что атаковать первыми нельзя. Чувство тревоги было почти осязаемым. Сол несколько раз вытер потеющую ладонь о&nbsp;свой жилет. Звуки моторов становились громче. До&nbsp;нас донёсся крик Майлза.

&mdash;&nbsp;Они направляются к&nbsp;воротам на&nbsp;скорости! Впереди броня с&nbsp;отвалом!!! Сразу стре... 

Его голос утонул в&nbsp;грохоте ломающихся ворот. Клиновой отвал прорезал&nbsp;их, словно нож масло. Военная бронемашина с&nbsp;пулемётом на&nbsp;вертлюге влетела во&nbsp;двор, ещё два белых пикапа остановились за&nbsp;воротами, съехав по&nbsp;обочинам. Солдаты высыпали из&nbsp;них, заняли оборонительную позицию, а&nbsp;вот их&nbsp;коллеги из&nbsp;&laquo;хамви&raquo; выскакивать не&nbsp;спешили. Очевидно, понимали, что у&nbsp;нас нет оружия для борьбы с&nbsp;не&nbsp;самой толстой, но&nbsp;всё&nbsp;же бронёй.

Спустя несколько мгновений начался настоящий&nbsp;ад. В&nbsp;унисон загрохотали винтовки и&nbsp;автоматы со&nbsp;всех сторон. Патроны и&nbsp;гранаты у&nbsp;нас в&nbsp;большом дефиците, а&nbsp;у&nbsp;противников, похоже, их&nbsp;куры не&nbsp;клюют. Я&nbsp;сидел и&nbsp;держал на&nbsp;прицеле двери и&nbsp;люк &laquo;хамви&raquo;. Краем глаза заметил, как отряд справа, которому я&nbsp;оставил единственную &laquo;лимонку&raquo;, буквально накрывали шквальным огнём. Они просто не&nbsp;могли высунуться. 

&mdash;&nbsp;Чёрт вас дери, бросайте гранаты под машины!!! &mdash;&nbsp;взревел Сол.

Парень, получивший от&nbsp;меня военную игрушку с&nbsp;кольцом, словно не&nbsp;слышал нас. Он&nbsp;смотрел на&nbsp;фрагментированное стальное яйцо в&nbsp;руках и&nbsp;беззвучно шевелил губами, будто&nbsp;бы читая молитву. Внезапно он&nbsp;закричал, резко вскочил и&nbsp;побежал к&nbsp;вражеской бронемашине. Две пули ударили в&nbsp;его плечо, но&nbsp;невероятным усилием он&nbsp;устоял на&nbsp;ногах и&nbsp;даже сумел забраться на&nbsp;джип. Мгновенье, и&nbsp;ещё несколько свинцовых жал врезались в&nbsp;мальчишку, но&nbsp;тот опять устоял, взглянул на&nbsp;меня, улыбнулся&nbsp;и... упал через щит пулемёта в&nbsp;люк &laquo;хамви&raquo;. Приглушённый взрыв буквально размазал внутренности экипажа и&nbsp;нашего мальца по&nbsp;стёклам броневика, заставив некоторые покрыться трещинами.

Другой боец из&nbsp;той&nbsp;же группы вскочил, надеясь воспользоваться замешательством, и&nbsp;открыл огонь по&nbsp;джипу за&nbsp;забором, но&nbsp;в&nbsp;него прилетела очередь. Солдат упал, разбросав руки, а&nbsp;на&nbsp;его разгрузке одиноко висела одна из&nbsp;последних наших &laquo;лимонок&raquo;. Сол, недолго думая, рванул к&nbsp;нему, как раз в&nbsp;этот момент прогремел взрыв. Брошенная из-за соседнего укрытия граната подорвала один из&nbsp;джипов. Двое солдат успели скрыться за&nbsp;соседним авто, третьего на&nbsp;бегу зацепил Майлз с&nbsp;вышки, а&nbsp;четвёртого отбросило взрывом, словно тряпичную куклу.

Ещё одна граната вылетела из-за укрытия в&nbsp;центре двора и&nbsp;попала прямиком на&nbsp;капот джипа. Один из&nbsp;бойцов противника, явно обладавший стальными нервами, не&nbsp;побежал, а&nbsp;дотянулся до&nbsp;неё&nbsp;и, не&nbsp;размахиваясь, отшвырнул. Траектория её&nbsp;полёта заставила моё сердце похолодеть. Она летела в&nbsp;сторону Сола, который снимал &laquo;эфку&raquo; с&nbsp;тела поверженного парня. Прогремел ещё один взрыв. Ким повалилась на&nbsp;меня, явно контуженная. В&nbsp;моих глазах всё поплыло, но&nbsp;я&nbsp;понимал, что у&nbsp;Сола не&nbsp;было шансов.

Чтобы прийти в&nbsp;себя, мне понадобилось некоторое время. Шума стрельбы не&nbsp;было. Рядом лежала Ким. Я&nbsp;приподнялся на&nbsp;локтях и&nbsp;начал трясти её&nbsp;за&nbsp;плечо. Она не&nbsp;реагировала. Приложив силы, я&nbsp;перевернул свою подругу, и&nbsp;у&nbsp;меня тут&nbsp;же перехватило дыхание. Глаза её&nbsp;были открыты, а&nbsp;правый висок словно вдавило в&nbsp;череп. Из&nbsp;ужасной раны сочилась кровь. Пульса не&nbsp;было. Там, где ещё недавно взорвалась граната, лежало тело Сола....`,
	buttons: [{
		to: '35',
		text: 'Попытаться встать',
	}]
}, {
	scene: '4',
	text: `Проверив винтовку, я&nbsp;взял подсумок с&nbsp;патронами и&nbsp;отдал магазин от&nbsp;1911-го с&nbsp;единственной гранатой одному из&nbsp;молодых бойцов, идущих к&nbsp;воротам. У&nbsp;него единственного был только пистолет, а&nbsp;по&nbsp;трясущимся рукам стало понятно, что парнишка чертовски нервничает. 

&mdash;&nbsp;Эта штука спасёт тебя,&nbsp;&mdash; сказал я&nbsp;ему, протягивая гранату. &mdash;&nbsp;Но&nbsp;распорядись ей&nbsp;грамотно. Хлопнув молодого по&nbsp;плечу, я&nbsp;бросился к&nbsp;вышке.

Майлз предупредил, чтобы я&nbsp;не&nbsp;стрелял с&nbsp;вышки без команды. Держа на&nbsp;прицеле приближающиеся автомобили, я&nbsp;представлял, какое напряжение сейчас испытывают наши ребята у&nbsp;ворот. Может, стоило занять место рядом с&nbsp;Солом и&nbsp;Ким? Но&nbsp;уже поздно. Машины находились в&nbsp;нескольких сотнях метров от&nbsp;ворот. Через шестикратную оптику можно было без труда разглядеть отвал на&nbsp;первом джипе, который мог&nbsp;бы протаранить наши ворота. Джо, сидящий внизу моей башни и&nbsp;также прикрывающий нашу передовую, крикнул:

&mdash;&nbsp;Майлз, они идут прямиком на&nbsp;ворота! У&nbsp;первого джипа клиновой отвал!
&mdash;&nbsp;Чёрт&nbsp;бы их&nbsp;побрал! Зеро, давай по&nbsp;водиле.

Поймав бойца в&nbsp;прицел, я&nbsp;взял упреждение и&nbsp;совершил свой первый на&nbsp;сегодня снайперский выстрел. Но&nbsp;калибра моей винтовки не&nbsp;хватило, чтобы пробить бронированное стекло бывшего военного внедорожника. Передёрнув затвор, я&nbsp;было прицелился в&nbsp;колесо, но&nbsp;затем быстро перебросил перекрестье на&nbsp;крышу &laquo;хамви&raquo;&nbsp;&mdash; там слетел брезент с&nbsp;трёхствольного пулемёта, который мог&nbsp;бы размотать в&nbsp;клочья и&nbsp;мою вышку, и&nbsp;весь лагерь. Недолго думая, я&nbsp;выстрелил в&nbsp;пулемётчика, показавшегося из&nbsp;люка. Боец упал на&nbsp;бронепластину, а&nbsp;могучий внедорожник разбил отвалом ворота и&nbsp;остановился, визжа тормозами и&nbsp;поднимая пыль покрышками.

Две другие машины остановились прямо за&nbsp;воротами, встав боком и&nbsp;сформировав укрытие для своих экипажей. Краем мозга я&nbsp;отметил, что остановившиеся по&nbsp;ту&nbsp;сторону ворот фордовские пикапы съехали по&nbsp;обочинам, будто целенаправленно не&nbsp;занимая основную дорогу. Но&nbsp;я&nbsp;решил не&nbsp;придавать этому значения и&nbsp;высматривал в&nbsp;оптику новые цели. А&nbsp;их&nbsp;не&nbsp;было. Бойцы из&nbsp;&laquo;хамви&raquo; чего-то ждали, прекрасно зная, что их&nbsp;крепкую броню не&nbsp;возьмёт наше оружие, хотя меткий бросок гранаты в&nbsp;люк пулемётчика...

&mdash;&nbsp;Гранаты!!! &mdash;&nbsp;послышался крик со&nbsp;двора.

Я&nbsp;увидел, как наши бойцы побежали в&nbsp;сторону от&nbsp;укреплений из&nbsp;коробок и&nbsp;мешков, но&nbsp;лишь один остался на&nbsp;своём месте. Тот самый молодой парень, он&nbsp;держал обе руки перед лицом&nbsp;и... молился? Вдруг он&nbsp;вскочил и&nbsp;с&nbsp;завидной скоростью рванул к&nbsp;бронемашине, занявшей двор. 

&mdash;&nbsp;Безумец... &mdash;&nbsp;прошептал&nbsp;я.

В&nbsp;следующий момент в&nbsp;перекрестье моего прицела оказался вражеский боец, открывший было огонь по&nbsp;бегущему мальчишке. Выстрел, и&nbsp;угроза захлебнулась кровью, а&nbsp;наш боец запрыгнул на&nbsp;капот, потом на&nbsp;крышу &laquo;хамви&raquo; и... нет, он&nbsp;не&nbsp;забросил гранату в&nbsp;люк, а&nbsp;просто нырнул туда вместе с&nbsp;ней. Спустя миг прогремел взрыв. Машина подпрыгнула на&nbsp;высокой подвеске и&nbsp;закачалась на&nbsp;рессорах, все её&nbsp;стёкла покраснели и&nbsp;потрескались, а&nbsp;из&nbsp;люка и&nbsp;щелей повалил дым.

Героический поступок молодого и&nbsp;нервного бойца убрал одну из&nbsp;угроз, но&nbsp;нападавших это не&nbsp;остановило. Прогремело несколько взрывов и&nbsp;вооружённые люди из&nbsp;джипов рванули во&nbsp;двор через брешь в&nbsp;воротах. Мне ничего не&nbsp;оставалось, кроме как открыть по&nbsp;ним огонь. Беречь патроны не&nbsp;было смысла, но&nbsp;и&nbsp;промахи не&nbsp;помогли&nbsp;бы нам выжить. Сердце бешено колотилось, и&nbsp;приходилось прикладывать немалые усилия, чтобы ловить противников в&nbsp;прицел. 

В&nbsp;моём распоряжении было всего два магазина. Я&nbsp;помнил каждую пулю, поразившую цель. Лишь первый выстрел по&nbsp;лобовому стеклу был потрачен впустую. Каждый последующий попадал в&nbsp;цель, выводя врага из&nbsp;строя моментально или нанося смертельные увечья. Стрельба затихала. Ещё один стрелок с&nbsp;винтовкой, занимавший позицию под моей вышкой, замолк. Патроны кончились? Или его убили? Не&nbsp;время об&nbsp;этом думать. Сам бой переместился за&nbsp;установленные ограждения из&nbsp;поля моего зрения. Я&nbsp;начал всматриваться в&nbsp;прицел, чтобы подстрелить ещё кого-нибудь.

В&nbsp;перекрестье попал Сол. Я&nbsp;почувствовал, как затряслись руки, а&nbsp;давление в&nbsp;висках стало таким сильным, что голова начала раскалываться. Он&nbsp;лежал в&nbsp;паре метров от&nbsp;джипа с&nbsp;отвалом, руки его были прижаты к&nbsp;животу, но&nbsp;смущало не&nbsp;это. Под ним была пугающих размеров лужа крови. Сол что-то крикнул, направив взгляд в&nbsp;сторону ворот. Я&nbsp;повёл прицел в&nbsp;том направлении и&nbsp;заметил Ким. Её&nbsp;удерживал один из&nbsp;вражеских бойцов в&nbsp;качестве живого щита. 

Я&nbsp;судорожно начал перезаряжать винтовку, но&nbsp;мое внимание привлёк столб пыли, поднявшийся за&nbsp;воротами. Оптический прицел от&nbsp;досады захотелось разбить после того, как я&nbsp;взглянул в&nbsp;него. К&nbsp;нам приближался тентованный грузовик. Очевидно, что он&nbsp;был полон вражеских солдат. Что делать? Что теперь делать? Мне не&nbsp;хватит двух патронов, чтобы перестрелять их&nbsp;всех. Даже если попаду в&nbsp;водителя, остальные просто доберутся пешком. Нужен взрыв, но&nbsp;свою единственную гранату я&nbsp;отдал Майлзу.

Снова повёл прицелом в&nbsp;сторону пленённой Ким, но&nbsp;остановил его чуть раньше. Цистерна! Наша цистерна с&nbsp;горючим была недалеко от&nbsp;ворот. Но&nbsp;как мне выстрелить в&nbsp;неё, чтобы не&nbsp;навредить друзьям? Права на&nbsp;ошибку с&nbsp;двумя патронами у&nbsp;меня не&nbsp;оставалось.`,
	buttons: [{
		to: '5',
		text: 'Стрелять в&nbsp;удерживающего подругу врага',
	}, {
		to: '6',
		text: 'Поискать другой выход',
	}, {
		to: '7',
		text: 'Стрелять в&nbsp;цистерну',
	}]
}, {
	scene: '5',
	text: `Ким можно спасти. Она ведь ещё жива&nbsp;и, судя по&nbsp;всему, не&nbsp;ранена. Устраню врага и&nbsp;проблема решена. Я&nbsp;задержал дыхание и&nbsp;постарался сконцентрироваться, насколько это возможно. Этот выродок в&nbsp;кепке зажимал её&nbsp;шею правой рукой: мне была видна лишь часть его головы. Они слишком близко друг к&nbsp;другу, можно запросто задеть Ким. Я&nbsp;взял чуть выше и&nbsp;левее его головы, зафиксировал прицел и&nbsp;нажал на&nbsp;спусковой крючок. Пуля прошла рядом и&nbsp;угодила в&nbsp;землю. Я&nbsp;громко выругался.

Грузовик влетел в&nbsp;ворота, протаранив закрывавший въезд и&nbsp;окровавленный изнутри &laquo;хамви&raquo;, на&nbsp;нём тоже был клиновой отвал. Хорошо, что эта махина не&nbsp;прибыла сюда первой... В&nbsp;прицел я&nbsp;видел, как Ким развернулась в&nbsp;сторону вышки и&nbsp;что-то выкрикнула. Если&nbsp;бы в&nbsp;этот момент была стрельба, я&nbsp;бы точно ничего не&nbsp;расслышал, но&nbsp;она вложила в&nbsp;него все силы и&nbsp;своё отчаяние:

&mdash;&nbsp;Цистерна, Зеро! &mdash;&nbsp;неприятельская рука стиснула её&nbsp;горло сильнее, и&nbsp;она начала брыкаться.

Других вариантов не&nbsp;оставалось...`,
	buttons: [{
		to: '7',
		text: 'Стрелять в&nbsp;цистерну',
	}]
}, {
	scene: '6',
	text: `Всего два патрона? Может, спуститься и&nbsp;броситься на&nbsp;помощь? Сол выглядел ужасно, но&nbsp;хотя&nbsp;бы Ким не&nbsp;ранена. У&nbsp;них наверняка есть гранаты, чтобы успеть разобраться с&nbsp;грузовиком. А&nbsp;если я&nbsp;не&nbsp;успею туда добраться? Мне нужно помочь им&nbsp;отсюда. Я&nbsp;идиот! Надо было идти с&nbsp;ними! Тогда этого могло&nbsp;бы и&nbsp;не&nbsp;случиться. Нужно собраться.

Если я&nbsp;сейчас выстрелю в&nbsp;цистерну, то&nbsp;уложу к&nbsp;чертям всех. Может, стоит помочь Ким? У&nbsp;неё будет немного времени, чтобы оттащить Сола на&nbsp;безопасное расстояние. Да, отсюда будет сложно попасть, но&nbsp;у&nbsp;меня есть две попытки. Но&nbsp;что, если промахнусь с&nbsp;первого выстрела по&nbsp;этому гаду в&nbsp;кепке? Что, если вообще попаду в&nbsp;Ким?

Весь этот поток мыслей промелькнул в&nbsp;моей голове за&nbsp;доли секунды. Нужно было что-то решать. Я&nbsp;пробежался глазами по&nbsp;двору в&nbsp;поисках спасительной идеи. Ничего не&nbsp;было. У&nbsp;меня всего два пути...`,
	buttons: [{
		to: '5',
		text: 'Стрелять в&nbsp;удерживающего подругу врага',
	}, {
		to: '7',
		text: 'Стрелять в&nbsp;цистерну',
	}]
}, {
	scene: '7',
	text: `Сол перестал двигаться в&nbsp;рубиновой луже, а&nbsp;удерживающий Ким солдат ударил её&nbsp;по&nbsp;виску рукоятью пистолета. Из&nbsp;раны потекла кровь, а&nbsp;сама девушка обмякла в&nbsp;неприятельских руках. Пистолет исчез в&nbsp;кобуре, из-за пояса появился нож... Я&nbsp;не&nbsp;был уверен, пережила&nbsp;ли она удар. Подмоги ждать было неоткуда. Из&nbsp;грузовика, ворвавшегося во&nbsp;двор, уже начали вылезать солдаты. В&nbsp;прицеле появилась цистерна. Я&nbsp;не&nbsp;смог побороть желание взглянуть на&nbsp;товарищей в&nbsp;последний раз... Сол не&nbsp;подавал признаков жизни, а&nbsp;в&nbsp;горло Ким уже вошла вражеская сталь... Под жуткий скрежет собственных зубов я&nbsp;сделал выстрел.

Взрыв был невероятной мощи. Пару соседних построек и&nbsp;часть оборонительной стены попросту снесло. Грузовик отлетел, словно он&nbsp;ничего не&nbsp;весил, и&nbsp;загорелся. Кругом были огонь и&nbsp;тела. Нечеловеческие крики боли я&nbsp;буду помнить ещё долго. Шансов выжить не&nbsp;было ни&nbsp;у&nbsp;кого, у&nbsp;Сола и&nbsp;Ким в&nbsp;том числе. От&nbsp;осознания этого мне стало дурно...`,
	buttons: [{
		to: '8',
		text: 'Слезть с&nbsp;башни',
	}]
}, {
	scene: '8',
	text: `Взрыв цистерны поверг в&nbsp;панику остатки противников. Из-за укреплений показались люди. Побросав оружие, они бежали. Видимо, осознали, что подкрепление уничтожено и&nbsp;шансов на&nbsp;победу нет. За&nbsp;ними гнался Майлз с&nbsp;четырьмя людьми, но&nbsp;выстрелов не&nbsp;было слышно. Я&nbsp;бросился к&nbsp;воротам, чтобы отрезать им&nbsp;путь к&nbsp;отступлению. Пистолет в&nbsp;моей руке дал им&nbsp;понять, что план побега провалился.

&mdash;&nbsp;Вам некуда бежать, на&nbsp;колени! &mdash;&nbsp;крикнул Майлз.

На&nbsp;глаза мне попалось изувеченное тело Ким. Боец в&nbsp;кепке лежал на&nbsp;ней... Меня охватила жгучая ярость. Не&nbsp;осознавая, что делаю, я&nbsp;подбежал к&nbsp;одному из&nbsp;пленных&nbsp;и, приставив дуло к&nbsp;его лбу, выстрелил.

&mdash;&nbsp;Зеро, успокойся!!! &mdash;&nbsp;чей-то возглас пробился к&nbsp;моему сознанию сквозь туман гнева.

Я&nbsp;почувствовал, как две пары рук обхватили меня и&nbsp;оттащили от&nbsp;&laquo;Цикад&raquo;. По&nbsp;эмблемам было понятно, что это одни из&nbsp;них.

&mdash;&nbsp;Не&nbsp;убивайте! Пожалуйста! Мы&nbsp;просто выполняли приказ. Что вы&nbsp;хотите? Мы&nbsp;можем рассказать, где наша база. Там уйма припасов и&nbsp;оружия,&nbsp;&mdash; молодой парень с&nbsp;кровью на&nbsp;щеке заплакал, выдавая спасительную речь.
&mdash;&nbsp;Заткнись, Боб. Это не&nbsp;поможет,&nbsp;&mdash; со&nbsp;злобой в&nbsp;голосе процедил второй.
&mdash;&nbsp;Плевать. Не&nbsp;убивайте! Я&nbsp;всё расскажу...

Меня уже отпустили. Пистолет всё ещё был в&nbsp;моей руке. Сол лежал в&nbsp;нескольких метрах от&nbsp;меня. Если он&nbsp;мёртв, так почему эти подлецы должны жить? Отсюда я&nbsp;не&nbsp;промахнусь по&nbsp;обоим.`,
	buttons: [{
		to: '9',
		text: 'Убить &laquo;Цикад&raquo;',
	}, {
		to: '10',
		text: 'Выслушать&nbsp;их',
	}]
}, {
	scene: `9`,
	text: `Хотя патроны у&nbsp;нас в&nbsp;дефиците, но&nbsp;только месть сможет облегчить чувство утраты. Я&nbsp;поднимаю пистолет, но&nbsp;ощущаю, как мою руку останавливает тонкая, но&nbsp;крепкая рука. Это Джина. Супруга Сола. Она смотрит мне в&nbsp;глаза и&nbsp;произносит:

&mdash;&nbsp;Мёртвых уже не&nbsp;вернуть, а&nbsp;после этой бойни нам и&nbsp;нашим детям понадобятся припасы.`,
	buttons: [{
		to: `10`,
		text: `Убрать пистолет в&nbsp;кобуру`,
	}]
},


	{
		scene: `10`,
		text: `Джина подозвала Майлза жестом:

&mdash;&nbsp;Когда-то один друг научил меня интересному методу проверки правдивости информации, погоди. 

Одного пленного она оттащила в&nbsp;сторону. Своего сына женщина попросила принести листок и&nbsp;карандаш. Парень быстро прибежал со&nbsp;всем необходимым. 

&mdash;&nbsp;Ты! &mdash;&nbsp;обратилась она к&nbsp;пленному с&nbsp;листком&nbsp;&mdash; Пишешь координаты вашей базы с&nbsp;припасами. Давай! 

Ему развязали руки. Джина повернулась ко&nbsp;мне:

&mdash;&nbsp;Зеро, а&nbsp;второй пусть скажет тебе на&nbsp;ухо то&nbsp;же самое, только тихо. 

Полученные данные на&nbsp;удивление сошлись. Я&nbsp;отошёл в&nbsp;сторону и&nbsp;начал отмечать потенциальный маршрут на&nbsp;туристической карте. До&nbsp;слуха моего донеслось отвратительное журчание... Подняв глаза, я&nbsp;увидел, как оба бойца упали с&nbsp;перерезанными глотками. Джина и&nbsp;её&nbsp;сын вытирали ножи под тяжёлым, но&nbsp;одобрительным взглядом Майлза. Невелика потеря. 
`,
		buttons: [{
			to: `11`,
			text: `&laquo;Славно, теперь у&nbsp;меня есть карта!&raquo;`,
		}]
	}, {
		scene: `11`,
		text: `Карта&nbsp;&mdash; замечательная штука, конечно, с&nbsp;ней я&nbsp;быстро преодолел большую часть пути. Жаль, что заражённые на&nbsp;ней не&nbsp;отмечались. Мы&nbsp;понесли огромные потери, и&nbsp;я&nbsp;вызвался пойти на&nbsp;разведку один. Выдвинулся на&nbsp;рассвете, так как путь предстоял неблизкий. До&nbsp;Солт-Лейк-Сити я&nbsp;добрался без приключений. Мы&nbsp;никогда не&nbsp;ходили в&nbsp;северную часть города, а&nbsp;осматривали лишь окраины. Поэтому в&nbsp;какой-то момент для меня начались совершенно незнакомые места.

Хоть я&nbsp;и&nbsp;осторожничал, но&nbsp;едва не&nbsp;напоролся на&nbsp;патруль&nbsp;&mdash; двое бойцов в&nbsp;форме и&nbsp;с&nbsp;автоматами шли, разговаривая о&nbsp;чём-то своём и&nbsp;озираясь по&nbsp;сторонам. Недолго думая, я&nbsp;бросился к&nbsp;ближайшему укрытию. Им&nbsp;стал рекламный щит магазина неподалёку, весь разбитый и&nbsp;проржавевший, но&nbsp;всё ещё способный скрыть меня от&nbsp;лишних глаз. 

Что&nbsp;же теперь делать? Как лучше поступить? Устроить внезапную атаку или попытаться обойти&nbsp;их? 

Из&nbsp;этих мыслей меня выдернул мощный хлопок, и&nbsp;я&nbsp;увидел, как вокруг патрульных поднялась пыль... да&nbsp;какая к&nbsp;чёрту пыль, это&nbsp;же споры! Спустя несколько секунд из-за угла стоящего поодаль дома вынеслись два бегуна, а&nbsp;следом за&nbsp;ними своей неспешной походкой топал шаркун. Эти твари чертовски опасны из-за умения бросать части своего поражённого кордицепсом тела, распространяя споры. 

Так варианты выбора резко уменьшились, а&nbsp;времени на&nbsp;раздумья почти не&nbsp;осталось. Метрах в&nbsp;пятнадцати за&nbsp;мной&nbsp;&mdash; дверь в&nbsp;какой-то магазин. Я&nbsp;могу аккуратно отойти туда... или воспользоваться замешательством, пока упыри будут лакомиться своей добычей, и&nbsp;расстрелять их&nbsp;с&nbsp;этой позиции. Расстояние метров&nbsp;20, не&nbsp;больше.
`,
		buttons: [{
			to: `11A`,
			text: `Открыть огонь`,
		}, {
			to: `12`,
			text: `Пятиться в&nbsp;магазин`,
		}]
	}, {
		scene: `11A`,
		text: `Желание выжить у&nbsp;патрульных было сильно, несмотря на&nbsp;споры. Они начали стрелять по&nbsp;бегунам, оказавшись на&nbsp;земле в&nbsp;смертоносном вихре частиц шаркуна. Я&nbsp;последовал их&nbsp;примеру, поняв, что это два потенциальных трупа, отчаянно цепляющихся за&nbsp;жизнь. Казалось, они не&nbsp;представляют для меня опасности. Как&nbsp;же я&nbsp;заблуждался...

Услышав чужие выстрелы, один из&nbsp;бойцов развернулся и&nbsp;пустил пулю, буквально выбившую из-под меня мою собственную ногу. Потеря равновесия и&nbsp;дикая боль были внезапными. Слегка растерявшись после падения, я&nbsp;даже не&nbsp;заметил, как один из&nbsp;бегунов приблизился ко&nbsp;мне вплотную. Выставленная вперёд винтовка остановила его руки, он&nbsp;хаотично дёргался, пытаясь дотянуться до&nbsp;меня. Шансы на&nbsp;спасение растаяли, когда в&nbsp;поле моего зрения попала мерзкая лапа шаркуна. Секундное замешательство привело к&nbsp;мощнейшему укусу в&nbsp;шею...
`,
		buttons: [{
			to: `0`,
			text: `Начать приключение сначала`,
		}, {
			to: `11`,
			text: `Вернуться к&nbsp;контрольной точке`,
		}]
	}, {
		scene: `12`,
		text: `Чтобы не&nbsp;привлекать внимание сражающихся, я&nbsp;тихонько открыл дверь и&nbsp;на&nbsp;корточках пробрался внутрь магазина. В&nbsp;нём бело темновато, но&nbsp;я&nbsp;побоялся включать фонарь, так как его могли заметить с&nbsp;улицы. Ничего примечательного вокруг не&nbsp;было, но&nbsp;мой взгляд привлёк маленький клочок бумаги, лежащий на&nbsp;прилавке. 

Сдув с&nbsp;него пыль, я&nbsp;понял, что это коллекционная карточка некой старой игры. Надпись на&nbsp;ней гласила: &laquo;Гражданин Коктейль&raquo;. Что-то в&nbsp;ней казалось мне знакомым. Могучий герой, обвешанный бутылками с&nbsp;зажигательной смесью. Как только карта оказалась у&nbsp;меня в&nbsp;руках, я&nbsp;почувствовал, что уже где-то видел&nbsp;её. Сейчас у&nbsp;меня не&nbsp;было времени размышлять над этим, и&nbsp;я&nbsp;просто спрятал её&nbsp;в&nbsp;карман. 

За&nbsp;окном всё стихло. Звуки боя пропали. Версий у&nbsp;меня было две: либо патруль уничтожил нападавших, либо оба бойца стали ужином для бегунов и&nbsp;шаркуна. Это мне предстояло выяснить, так как запасной выход, через который я&nbsp;мог&nbsp;бы покинуть здание, был завален хламом.
`,
		buttons: [{
			to: `13`,
			text: `Выйти из&nbsp;магазина`,
		}]
	}, {
		scene: `13`,
		text: `Ещё приближаясь к&nbsp;выходу, я&nbsp;услышал чавкающие звуки и&nbsp;нетерпеливое хрипение. Выглянув на&nbsp;улицу, увидел, как один бегун лежит мёртвый, а&nbsp;другой впивается зубами в&nbsp;плоть одного из&nbsp;патрульных. Рядом стоит и&nbsp;хрипит шаркун, хищно озираясь. Небось, думает, в&nbsp;кого&nbsp;бы запустить кусок своего ядовитого тела. 

Что&nbsp;же, выбора не&nbsp;остаётся, здесь без боя не&nbsp;обойтись. Осталось приоритизировать цели...`,
		buttons: [{
			to: `14`,
			text: `Спрятаться за&nbsp;машину неподалёку и&nbsp;выстрелить из&nbsp;пистолета в&nbsp;шаркуна`,
		}, {
			to: `16`,
			text: `Вернуться к&nbsp;щиту и&nbsp;выстрелить из&nbsp;пистолета в&nbsp;бегуна`,
		}, {
			to: `17`,
			text: `Выстрелить из&nbsp;винтовки в&nbsp;шаркуна прямо из&nbsp;дверного проёма`,
		}, {
			to: `15`,
			text: `Выстрелить из&nbsp;винтовки в&nbsp;бегуна, хорошенько прицелившись`,
		}]
	}, {
		scene: `14`,
		text: `Я стреляю в&nbsp;шаркуна из&nbsp;пистолета. Для этого требуется несколько выстрелов, и&nbsp;за&nbsp;это время бегун успевает сократить дистанцию. Беру его на&nbsp;мушку, но&nbsp;вместо выстрела слышу предательский щелчок. Осечка! Следующее, что успел отметить мой мозг, прежде чем провалиться в&nbsp;вечное небытие&nbsp;&mdash; это крепкий хват на&nbsp;шее и&nbsp;острые зубы, разрывающие сонную артерию.`,
		buttons: [{
			to: `0`,
			text: `Начать приключение сначала`,
		}, {
			to: `13`,
			text: `Вернуться к&nbsp;контрольной точке`,
		}]
	}, {
		scene: `15`,
		text: `Я стреляю из&nbsp;винтовки в&nbsp;бегуна, хорошенько прицелившись, голова последнего разлетается, как переспелый арбуз. Но&nbsp;не&nbsp;успеваю перебросить прицел на&nbsp;шаркуна, как под ногами взрывается ядовитая бомба, ослепляя и&nbsp;буквально выжигая слизистую. Следующее, что успел отметить мой мозг, прежде чем провалиться в&nbsp;вечное небытие&nbsp;&mdash; это крепкий хват на&nbsp;шее и&nbsp;острые зубы, разрывающие сонную артерию.`,
		buttons: [{
			to: `0`,
			text: `Начать приключение сначала`,
		}, {
			to: `13`,
			text: `Вернуться к&nbsp;контрольной точке`,
		}]
	}, {
		scene: `16`,
		text: `Я возвращаюсь к&nbsp;щиту, прицеливаюсь в&nbsp;бегуна и&nbsp;задерживаю дыхание, чтобы стабилизировать мушку. Выстрел, и&nbsp;пуля 45-го калибра вышибает тучку крови. Тварь падает замертво. Как хорошо, что у&nbsp;бегунов ещё грибная бронепластина на&nbsp;голове не&nbsp;выросла, которая заставляет тратить аж&nbsp;пару &laquo;маслин&raquo; на&nbsp;щелкунов. Попадание с&nbsp;20&nbsp;метров&nbsp;&mdash; хороший показатель. Перебрасываю прицел на&nbsp;шаркуна и&nbsp;вижу, как этот мерзкий увалень замахивается, держа в&nbsp;лапе кусок своей ядовитой плоти. Прячусь. Спустя секунду раздаётся мощный удар об&nbsp;рекламный щит, прикрывший меня от&nbsp;ядовитых спор. &laquo;Хоть какой-то толк от&nbsp;этого дерьма&raquo;&nbsp;&mdash; промелькнула мысль у&nbsp;меня в&nbsp;голове, пока тело на&nbsp;автомате несло за&nbsp;пикап неподалёку. Развернувшись, ловлю на&nbsp;мушку ядовитого ублюдка, замешкавшегося в&nbsp;добыче новой бомбы откуда-то из-под мышки, и&nbsp;выпускаю три последних патрона ему в&nbsp;голову.

Быстро озираюсь вокруг в&nbsp;поисках внезапной опасности и&nbsp;с&nbsp;облегчением выдыхаю, не&nbsp;обнаружив ни&nbsp;малейшего её&nbsp;признака. Теперь самое время собирать трофеи.`,
		buttons: [{
			to: `18`,
			text: `Осмотреть тела патрульных`,
		}]
	}, {
		scene: `17`,
		text: `Я стреляю в&nbsp;шаркуна прямо из&nbsp;дверного проёма, и&nbsp;ядовитый увалень падает от&nbsp;точного попадания в&nbsp;голову. Бегун вскакивает на&nbsp;ноги и&nbsp;озирается, пытаясь определить источник звука. Беру эту тварь на&nbsp;прицел, встречаюсь с&nbsp;ним глазами и&nbsp;плавно тяну спуск, наслаждаясь этим моментом. Звук выстрела и&nbsp;треск пробиваемого остроконечной пулей черепа сливаются воедино.

Опускаю винтовку и&nbsp;отправляю её&nbsp;на&nbsp;&laquo;трёхточке&raquo; за&nbsp;плечо, а&nbsp;в&nbsp;руках оказывается верный 1911-ый правительственной модели. Осторожно выглядываю из&nbsp;магазина. Преждевременная радость уже погубила не&nbsp;одну жизнь, когда очевидность победы оказывалась иллюзией. Даже во&nbsp;время наших патрулей такое происходило. В&nbsp;основном с&nbsp;горячими и&nbsp;молодыми бойцами. Хм... откуда прибыло это воспоминание? В&nbsp;Эмигрейшн Оакс я&nbsp;был единственным, так сказать, молодняком. Не&nbsp;по&nbsp;возрасту, конечно, но&nbsp;по&nbsp;участию в&nbsp;патрульной службе. Ладно, время посмотреть, какие трофеи уготовили боги войны.`,
		buttons: [{
			to: `18`,
			text: `Осмотреть тела патрульных`,
		}]
	}, {
		scene: `18`,
		text: `Подойдя к&nbsp;телам патрульных, я&nbsp;присвистнул и&nbsp;отметил для себя: в&nbsp;бою против других людей первого, чего лишается погибший&nbsp;&mdash; это содержимого своих карманов. Второго, вернее, но&nbsp;это уже философия. Когда&nbsp;же погибаешь от&nbsp;лап злобного гриба&nbsp;&mdash; атаке подвергаются твои голова и&nbsp;конечности. А&nbsp;вот кармашки и&nbsp;оружие остаются нетронутыми... хоть и&nbsp;страдают от&nbsp;крови, слюны и&nbsp;прочих не&nbsp;самых приятных выделений. Бррр!

Так, отставить брезгливость! Нужно собрать результат. Пистолеты мне не&nbsp;нужны, есть собственный короткоствольный друг, но&nbsp;патроны в&nbsp;магазинах остаться могли. Опустошил рукоятки. Я&nbsp;насчитал пять свинцовых шансов на&nbsp;выживание. Ещё один вытряхнул из&nbsp;патронника. В&nbsp;кармане одного бойца нашлась зажигалка Zippo и&nbsp;мятая пачка самокруток с&nbsp;непонятным содержимым. Впрочем, в&nbsp;этом мире никого не&nbsp;заботит легальность чего-либо, каждый убивается как хочет. Во&nbsp;всех смыслах. Папиросы я&nbsp;оставил лежать на&nbsp;земле, а&nbsp;вот зажигалку прихватил. Всё-таки карманный источник огня&nbsp;&mdash; великое достижение человечества. 

Нагрудный карман рубашки второго звенящего воина порадовал меня совсем уж&nbsp;роскошной находкой&nbsp;&mdash; парой сушеных колбасок, которые принято называть &laquo;охотничьими&raquo;. Кажется, всё. Нет, краем глаза замечаю какой-то блеск на&nbsp;трупе шаркуна&nbsp;и, подойдя поближе, вижу ствол штурмовой винтовки. Преодолевая брезгливость, подталкиваю тушу на&nbsp;бок и&nbsp;с&nbsp;разочарованием признаю, что настолько запутавшееся в&nbsp;ремне оружие, утопленное в&nbsp;споровых мутациях, я&nbsp;просто не&nbsp;достану без какого-то острого инструмента. Плевать. Есть пистолет, патронами я&nbsp;разжился, а&nbsp;идти осталось недолго.

Начав жевать первую колбаску, я&nbsp;достал из&nbsp;собственного кармана карту и&nbsp;развернул&nbsp;её, чтобы проверить маршрут. Вряд&nbsp;ли где-то рядом есть опасность, иначе&nbsp;бы на&nbsp;выстрелы и&nbsp;крики уже давно сбежались. Судя по&nbsp;начертанному маршруту, до&nbsp;базы оставалось рукой подать.`,
		buttons: [{
			to: `19`,
			text: `Направиться к&nbsp;&laquo;Цикадам&raquo; по&nbsp;карте`,
		}]
	}, {
		scene: `19`,
		text: `Подходя к&nbsp;зданию и&nbsp;помня силы &laquo;Цикад&raquo;, отправленные на&nbsp;штурм нашего лагеря, я&nbsp;опасался, что на&nbsp;их&nbsp;базе меня встретит целая армия. Ну&nbsp;не&nbsp;могли&nbsp;же они, в&nbsp;самом деле, отправить такую кучу машин и&nbsp;бойцов, не&nbsp;имея достаточного резерва. Мои ожидания не&nbsp;оправдались. Тут практически не&nbsp;было охраны, единственного скучающего бойца удалось нейтрализовать без шума и&nbsp;пыли. Наконец-то внутри! Из&nbsp;холла вели три коридора:`,
		buttons: [{
			to: `20`,
			text: `Пойти налево по&nbsp;коридору, из&nbsp;которого доносится лёгкий шум`,
		}, {
			to: `22`,
			text: `Пойти прямо по&nbsp;коридору с&nbsp;грязным полом`,
		}, {
			to: `23`,
			text: `Пойти направо по&nbsp;длинному чистому коридору`,
		}]
	}, {
		scene: `20`,
		text: `Я решил сначала проверить источник шума. После двух поворотов мне попалась на&nbsp;глаза приоткрытая дверь, из&nbsp;комнаты доносилась музыка. Приятный лёгкий джаз! Явно из&nbsp;старой аудиосистемы. Её&nbsp;забыл выключить охранник, которого я&nbsp;вырубил на&nbsp;выходе? Или там кто-то есть?`,
		buttons: [{
			to: `21`,
			text: `Проверить комнату`,
		}, {
			to: `22`,
			text: `Вернуться и&nbsp;пойти по&nbsp;коридору с&nbsp;грязным полом`,
		}, {
			to: `23`,
			text: `Вернуться и&nbsp;проверить длинный чистый коридор`,
		}]
	}, {
		scene: `21`,
		text: `Тихонько прокравшись к&nbsp;двери, я&nbsp;заглянул в&nbsp;комнату и&nbsp;встретился взглядом с&nbsp;накаченным детиной, сидящим в&nbsp;одиночестве над шахматной доской.

&mdash;&nbsp;Нарушитель!!! &mdash;&nbsp;проревел&nbsp;он, вскочив и&nbsp;достав пистолет.

Я&nbsp;выстрелил в&nbsp;него и&nbsp;попал в&nbsp;грудь! С&nbsp;двухъярусных кроватей, стоящих вдоль этого большого помещения, спрыгнуло четверо человек. Нужно было бежать! Преодолев уже знакомые повороты до&nbsp;холла, я&nbsp;рванул к&nbsp;выходу из&nbsp;здания. Послышались выстрелы. Мою ногу словно хлестануло плетью. Я&nbsp;упал на&nbsp;четвереньки, выронив свой пистолет. Попытку встать пресёк сильный удар ногой под дых, следующий по&nbsp;лицу, по&nbsp;ногам... Под градом ударов я&nbsp;начал терять сознание. Потом всё прекратилось.

&mdash;&nbsp;Если&nbsp;бы ты&nbsp;не&nbsp;убил Вайса в&nbsp;казарме, мы&nbsp;могли&nbsp;бы дать тебе шанс. Но&nbsp;теперь прощай, сволочь!

Выстрел...`,
		buttons: [{
			to: `0`,
			text: `Начать приключение сначала`,
		}, {
			to: `19`,
			text: `Вернуться к&nbsp;контрольной точке`,
		}]
	}, {
		scene: `22`,
		text: `Как только я&nbsp;достиг конца коридора, мне стало понятно, почему пол был настолько грязным. Он&nbsp;вёл к&nbsp;складу. Помещение было частично заставлено коробками, но&nbsp;большая решётчатая дверь мешала до&nbsp;них добраться. Что в&nbsp;коробках? Узнать я&nbsp;не&nbsp;мог. Слева была ещё одна дверь, у&nbsp;которой по&nbsp;отдельности открывались верхняя и&nbsp;нижняя части. На&nbsp;дубовом столе лежала здоровенная тетрадь. Последняя запись в&nbsp;ней отражала список выданного оружия, амуниции и&nbsp;припасов. Может быть, всё это получили люди, напавшие на&nbsp;нашу коммуну? В&nbsp;любом случае попасть на&nbsp;склад и&nbsp;проверить содержимое коробок нет возможности. Может, где-то есть ключ?`,
		buttons: [{
			to: `20`,
			text: `Пойти по&nbsp;коридору, из&nbsp;которого всё ещё доносится лёгкий шум`,
		}, {
			to: `23`,
			text: `Вернуться и&nbsp;проверить длинный чистый коридор`,
		}]
	}, {
		scene: `23`,
		text: `В чистом коридоре было несколько дверей, но&nbsp;все они оказались заперты. Массивная лестница вела на&nbsp;второй этаж. Поднявшись тихо по&nbsp;ступенькам, я&nbsp;прислушался. Никакого шума не&nbsp;было. В&nbsp;слабо освещённом фойе меня встретили ещё двери. Две из&nbsp;них вели в&nbsp;кабинеты, заваленные хламом, две были закрыты, а&nbsp;ещё одна вела в&nbsp;маленький кабинет. Тут мог&nbsp;бы принимать врач: стол со&nbsp;стулом, здоровенный сейф, расположившийся на&nbsp;полу, два стеллажа с&nbsp;книгами по&nbsp;медицине и&nbsp;тумба. Пыли практически не&nbsp;было, а&nbsp;значит, тут регулярно кто-то хозяйничает.

Первое что хотелось проверить&nbsp;&mdash; сейф, но&nbsp;в&nbsp;соседнем помещении послышался звук скрипящих петель.

&mdash;&nbsp;Ты&nbsp;опять запер нас в&nbsp;лаборатории? &mdash;&nbsp;донёсся женский голос.
&mdash;&nbsp;Меня бесит, Карли, что эти тупорылые вояки бесцеремонно вламываются без халатов и&nbsp;даже не&nbsp;пытаются дезинфицировать руки. Они могут погубить образцы. Неужели мы&nbsp;зря сюда вернулись за&nbsp;списками? Тут самая большая концентрация потенциальных кандидатов! А&nbsp;эти остолопы могут всё загубить, занеся в&nbsp;лабораторию какую-нибудь заразу.
&mdash;&nbsp;Остин, успокойся. Я&nbsp;же не&nbsp;против. Лучше&nbsp;бы не&nbsp;спрашивала...
&mdash;&nbsp;Прости. Того парня из&nbsp;Эмигрейшн Оакс что-то долго везут. Других здесь попросту нет. Если его упустят, нам придётся сворачиваться и&nbsp;возвращаться на&nbsp;базу в&nbsp;Санта-Барбаре. Погоди... а&nbsp;почему двери в&nbsp;моём кабинете открыты? 

Речь шла о&nbsp;комнате, в&nbsp;которой я&nbsp;находился. Сомневаться не&nbsp;приходилось. Нужно что-то предпринять! Можно выстрелить на&nbsp;слух: дверь тонкая, и&nbsp;они явно идут сюда. Раню одного человека&nbsp;&mdash; второго захвачу. Судя по&nbsp;разговорам, это не&nbsp;бойцы. Либо есть вариант спрятаться и&nbsp;захватить одного, а&nbsp;второго держать на&nbsp;мушке.
`,
		buttons: [{
			to: `24`,
			text: `Выстрелить через дверь`,
		}, {
			to: `25`,
			text: `Спрятаться за&nbsp;дверью`,
		}]
	}, {
		scene: `24`,
		text: `Я встал под углом к&nbsp;двери и&nbsp;нажал на&nbsp;спусковой крючок, примерно оценив, откуда доносятся звуки голосов. В&nbsp;маленькой комнате грохот выстрела был просто оглушительный... Из&nbsp;коридора донёсся женский крик. Попал! Я&nbsp;рванул вперёд, толкнув дверь плечом. На&nbsp;полу лежала девушка в&nbsp;халате. Мужчина, который был с&nbsp;ней, уже бежал к&nbsp;лестнице. Как он&nbsp;успел среагировать? Вытащив на&nbsp;бегу пистолет, я&nbsp;выстрелил. Штукатурка отлетела от&nbsp;стены. Человек в&nbsp;халате исчез из&nbsp;виду. Вот дерьмо! Нужно его догнать. С&nbsp;лестницы донеслись крики о&nbsp;помощи. Если он&nbsp;рассчитывает на&nbsp;охранника с&nbsp;улицы, то&nbsp;его ждёт неприятный сюрприз!

Такой&nbsp;же ждал и&nbsp;меня... Коридор первого этажа перекрыли пятеро вооруженных &laquo;Цикад&raquo;. Увидев меня, они открыли огонь. Не&nbsp;знаю, сколько пуль попало в&nbsp;меня перед тем, как я&nbsp;рухнул на&nbsp;лестницу... это было уже не&nbsp;важно...
`,
		buttons: [{
			to: `0`,
			text: `Начать приключение сначала`,
		}, {
			to: `23`,
			text: `Вернуться к&nbsp;контрольной точке`,
		}]
	}, {
		scene: `25`,
		text: `За дверью было достаточно места. С&nbsp;пистолетом наготове я&nbsp;встал за&nbsp;ней так, что любой заглянувший внутрь не&nbsp;смог&nbsp;бы меня увидеть. 

&mdash;&nbsp;Есть тут кто? &mdash;&nbsp;дверь слегка сдвинулась с&nbsp;места.
&mdash;&nbsp;Ты&nbsp;просто её&nbsp;не&nbsp;закрыл.
&mdash;&nbsp;Ладно. Может быть,&nbsp;&mdash; раздражённо буркнул мужской голос&nbsp;&mdash; Надо достать медкарту из&nbsp;сейфа, чтобы записать результаты эксперимента.

Продолжая разговор, они вошли внутрь. Мужчина в&nbsp;медицинском халате шёл первым, а&nbsp;за&nbsp;ним девушка в&nbsp;точно таком&nbsp;же одеянии. Когда она начала закрывать дверь, я&nbsp;схватил её&nbsp;и&nbsp;приставил пистолет к&nbsp;виску. Мужчина удивлённо округлил глаза.

&mdash;&nbsp;Ты?! Как ты&nbsp;сюда попал?!
&mdash;&nbsp;Ведите себя тихо или я&nbsp;подниму уровень свинца у&nbsp;вас в&nbsp;организме до&nbsp;неприлично высокой отметки.
&mdash;&nbsp;Карли, это&nbsp;он!
&mdash;&nbsp;Послушайте, мы&nbsp;ничего плохо не&nbsp;сделали,&nbsp;&mdash; сказала девушка.
&mdash;&nbsp;Только не&nbsp;стреляйте в&nbsp;неё. Что вы&nbsp;хотите? Вас привезли солдаты? Как вы&nbsp;освободились?

В&nbsp;первую очередь мне нужна была информация о&nbsp;припасах, но&nbsp;вопросы, которые он&nbsp;задавал, разбудили во&nbsp;мне любопытство. 

&mdash;&nbsp;Никто меня не&nbsp;привозил! Вы&nbsp;меня знаете?
&mdash;&nbsp;Конечно! Погоди. Ты&nbsp;не&nbsp;помнишь меня? &mdash;&nbsp;его взгляд выражал неподдельное удивление.
&mdash;&nbsp;Я&nbsp;ни&nbsp;черта не&nbsp;помню. Если вы&nbsp;что-то знаете&nbsp;&mdash; выкладывайте. Иначе дамочке придётся запачкать пол кровью из&nbsp;дырки в&nbsp;своей голове.
&mdash;&nbsp;Успокойтесь, мы&nbsp;безоружны. Я&nbsp;не&nbsp;помню вашего имени, но&nbsp;у&nbsp;меня в&nbsp;сейфе есть карта с&nbsp;вашими данными. Могу ввести код или сказать его вам.

Я&nbsp;жестом указал на&nbsp;сейф, и&nbsp;он&nbsp;медленно двинулся в&nbsp;его сторону. Всё не&nbsp;могло быть гладко. Моя заложница словно ждала удобного случая. Не&nbsp;успел я&nbsp;донести пистолет обратно до&nbsp;её&nbsp;виска, как она вцепилась в&nbsp;мою руку зубами. Боль была дикой, оружие выпало. Чтобы успокоить девушку, пришлось с&nbsp;размаху приложить её&nbsp;об&nbsp;стену. Мужчина в&nbsp;халате не&nbsp;стал дожидаться исхода стычки. Не&nbsp;открыв сейф, он&nbsp;выскочил из&nbsp;кабинета.

Я&nbsp;был быстр, однако стрелять было нельзя. Если убью&nbsp;&mdash; останусь без кода. Хитрый бегун не&nbsp;стал уходить по&nbsp;лестнице, побоявшись, что пуля настигнет его. Вместо этого он&nbsp;забежал в&nbsp;ту&nbsp;комнату, которая раньше была закрыта. Значит, это и&nbsp;есть лаборатория. 
`,
		buttons: [{
			to: `26`,
			text: `Выломать дверь силой`,
		}, {
			to: `27`,
			text: `Проверить, жива&nbsp;ли девушка, и&nbsp;попытаться выведать код у&nbsp;неё`,
		}, {
			to: `28`,
			text: `Попробовать вскрыть дверь`,
		}]
	}, {
		scene: `26`,
		text: `Церемониться с&nbsp;беглецом не&nbsp;хотелось. Сделав предварительно пару выстрелов по&nbsp;замку, я&nbsp;легко выбил дверь и&nbsp;зашёл внутрь. Доктор &laquo;Цикад&raquo; забился в&nbsp;угол.

&mdash;&nbsp;Сейчас мне нет смысла тебе ничего говорить. Ты&nbsp;же просто убьёшь меня!
&mdash;&nbsp;От&nbsp;желания назвать код будет зависеть, как быстро ты&nbsp;умрёшь. Подозреваю, что с&nbsp;простреленными коленями человек может жить ещё очень долго. Не&nbsp;желаешь пров...

Внезапно появившиеся в&nbsp;коридоре звуки прервали мою пафосную речь. Похоже, стрельба по&nbsp;двери привлекла чьё-то внимание... Топот ног и&nbsp;крики становились громче. Я&nbsp;бросился к&nbsp;дверному проёму и&nbsp;буквально налетел на&nbsp;двух здоровяков. Один из&nbsp;них вместо приветствия выстрелил в&nbsp;упор из&nbsp;дробовика. Меня отбросило в&nbsp;пустоту...`,
		buttons: [{
			to: `0`,
			text: `Начать приключение сначала`,
		}, {
			to: `25`,
			text: `Вернуться к&nbsp;контрольной точке`,
		}]
	}, {
		scene: `27`,
		text: `Похоже, я&nbsp;перестарался, отбиваясь от&nbsp;кусающейся девушки. Удар о&nbsp;стену был слишком сильным, и&nbsp;её&nbsp;череп не&nbsp;выдержал. Пол был весь в&nbsp;крови. Даже если она и&nbsp;знала код, с&nbsp;того света ждать помощи бесполезно. Нужно возвращаться.`,
		buttons: [{
			to: `26`,
			text: `Выломать дверь силой`,
		}, {
			to: `28`,
			text: `Попробовать вскрыть дверь`,
		}]
	}, {
		scene: `28`,
		text: `Как&nbsp;бы мне тихо вскрыть лабораторную дверь? Отмычек, мультитула или чего-то ещё, что помогло&nbsp;бы открыть замок без шума, у&nbsp;меня не&nbsp;было. Поджигать бессмысленно, а&nbsp;поддевать для срыва с&nbsp;петель бесполезно&nbsp;&mdash; конструкция коробки не&nbsp;позволит. Может, где-то есть запасной ключ? Мне приходит в&nbsp;голову идея проверить карманы подруги беглеца. В&nbsp;них оказывается связка из&nbsp;трёх ключей.

Отчётливо помню, что по&nbsp;пути видел ещё одну дверь, на&nbsp;удивление мощную. Быть может, один из&nbsp;трёх ключей от&nbsp;неё, а&nbsp;там припрятано что-то интересненькое? Третий-то наверняка от&nbsp;личных покоев девушки. Ай, не&nbsp;важно, и&nbsp;что только за&nbsp;мысли дурацкие лезут?!`,
		buttons: [{
			to: `29`,
			text: `Попробовать открыть одним из&nbsp;ключей увиденные по&nbsp;дороге стальные двери`,
		}, {
			to: `31`,
			text: `Открыть лабораторию`,
		}]
	}, {
		scene: `29`,
		text: `Я вернулся к&nbsp;могучей стальной двери, замеченной по&nbsp;дороге в&nbsp;лабораторию. Первый&nbsp;же мультилок со&nbsp;связки подошёл к&nbsp;замочной скважине. Поворот&nbsp;&mdash; и&nbsp;я&nbsp;услышал, как сдвинулись ригеля замка. На&nbsp;втором с&nbsp;половиной повороте ключ упёрся в&nbsp;блокиратор и&nbsp;дверь открылась, когда защёлка спряталась вслед за&nbsp;ригелями. Даже ручку нажимать не&nbsp;пришлось, удобно-то как! 

В&nbsp;помещении было темно, а&nbsp;в&nbsp;нос мне ударил зловонный запах. Инстинктивно пошарив рукою слева и&nbsp;справа по&nbsp;стенам возле двери, обнаружил выключатель.`,
		buttons: [{
			to: `30`,
			text: `Включить его`,
		}, {
			to: `31`,
			text: `Уйти и&nbsp;открыть лабораторию `,
		}]
	}, {
		scene: `30`,
		text: `Свет озарил комнату. Источником вони оказался щелкун, лежавший в&nbsp;клетке. Эти твари реагировали на&nbsp;звук. Если он&nbsp;жив, то&nbsp;он&nbsp;поднимет такой визг, что сюда сбежится вся округа. Какие эксперименты &laquo;Цикады&raquo; тут проводят?! Я&nbsp;замер на&nbsp;месте, но&nbsp;не&nbsp;из-за боязни наделать шума и&nbsp;привлечь внимание щелкуна. Причиной был бегун, которому свет помог очнуться. Он&nbsp;был в&nbsp;противоположной клетке, но&nbsp;при появлении источника света кинулся к&nbsp;её&nbsp;краю с&nbsp;диким криком. Щелкуну второго приглашения не&nbsp;требовалось. Чудовище вторило своему &laquo;коллеге&raquo;. Дело дрянь!

Я&nbsp;выскочил из&nbsp;помещения и&nbsp;побежал что есть мочи к&nbsp;лестнице первого этажа. Пробегая по&nbsp;холлу, услышал несколько мужских голосов из&nbsp;бокового коридора. Солдаты явно не&nbsp;спешили.

&mdash;&nbsp;Док опять забыл нас позвать. Вдруг эти твари его сожрут? Он&nbsp;не&nbsp;осознаёт опасности! Человек бесстрашный и&nbsp;безрассудный...

Похоже, я&nbsp;ушёл вовремя! Стоило задержаться ещё на&nbsp;пару секунд, и&nbsp;меня&nbsp;бы точно обнаружили. Наверное, нужно переждать, а&nbsp;потом попробовать вновь попасть туда. Пришлось пройти целый квартал, чтобы найти хотя&nbsp;бы мало-мальски безопасное место. Переждав там ночь, я&nbsp;выдвинулся обратно на&nbsp;утро и&nbsp;с&nbsp;ужасом обнаружил, что здание оказалось пустым. Они спешно похоронили тела девушки и&nbsp;того единственного охранника. Щелкун и&nbsp;бегун остались на&nbsp;месте, а&nbsp;сейф, к&nbsp;которому я&nbsp;так стремился, был открыт, но&nbsp;внутри уже ничего не&nbsp;было.

Не&nbsp;знаю, сколько буду ещё их&nbsp;искать, но&nbsp;моя охота за&nbsp;&laquo;Цикадами&raquo; длится уже несколько месяцев. Я&nbsp;вспомнил, что они говорили о&nbsp;Санта-Барбаре. Нужно добраться туда. Ведь мне хочется не&nbsp;только отомстить за&nbsp;друзей и&nbsp;выяснить причину нападения на&nbsp;коммуну, но&nbsp;и&nbsp;наконец-то узнать, кто я&nbsp;такой...`,
		//(КОНЦОВКА. Нейтральная. Зеро ничего не&nbsp;узнал, но&nbsp;остался жив. Он&nbsp;преследует &laquo;Цикад&raquo;.)
		buttons: [{
			to: `K1`,
			text: `Продолжить`,
		}]
	}, {
		scene: `31`,
		text: `Ключ легко открывает двери лаборатории. Мужчина в&nbsp;халате с&nbsp;ужасом смотрит на&nbsp;пистолет.

&mdash;&nbsp;Ты&nbsp;не&nbsp;кричишь&nbsp;&mdash; я&nbsp;не&nbsp;нервничаю!
&mdash;&nbsp;Если ты&nbsp;выстрелишь&nbsp;&mdash; сюда прибегут наши. Казарма на&nbsp;первом этаже! И&nbsp;я&nbsp;уверен, что ты&nbsp;побоялся сунуть туда свой нос,&nbsp;&mdash; хотя глаза его выражали страх, голос у&nbsp;него был ровный и&nbsp;спокойный.
&mdash;&nbsp;У&nbsp;меня нет цели тебя убивать. Но&nbsp;ты&nbsp;наплёл лишнего. Почему ты&nbsp;решил, что меня должны были привести солдаты? &mdash;&nbsp;я&nbsp;продолжал держать его на&nbsp;мушке.
&mdash;&nbsp;Целый отряд выехал тебя искать в&nbsp;Эмигрейшн Оакс! Ведь ты&nbsp;нулевой объект! Могу рассказать тебе всё с&nbsp;самого начала или сообщить код от&nbsp;сейфа, но&nbsp;если обещаешь не&nbsp;убивать меня. Условие простое: я&nbsp;должен выжить.
&mdash;&nbsp;Гарантий дать не&nbsp;могу...
`,
		buttons: [{
			to: `32`,
			text: `Узнать код`,
		}, {
			to: `33`,
			text: `Выслушать`,
		}]
	}, {
		scene: `32`,
		text: `&mdash; Говори чёртов код и&nbsp;я&nbsp;просто привяжу тебя к&nbsp;батарее и&nbsp;заткну рот. Ваши солдаты найдут тебя рано или поздно. Но&nbsp;если ты&nbsp;соврёшь&nbsp;&mdash; повторишь судьбу своей подружки.
&mdash;&nbsp;Карли мертва?! Ты&nbsp;чёртов ублюдок!!!
&mdash;&nbsp;Тише. Код.
&mdash;&nbsp;030789
&mdash;&nbsp;Умница!

Чтобы не&nbsp;перестараться, я&nbsp;аккуратно ударил его рукоятью пистолета. Он&nbsp;обмяк. Привязать тело помог халат с&nbsp;поясом&nbsp;&mdash; последний послужил верёвкой, а&nbsp;рукав&nbsp;&mdash; кляпом. Убедившись в&nbsp;надёжности самодельной смирительной конструкции, я&nbsp;отправился к&nbsp;сейфу.

Код сработал. Медкарта больше напоминала полноценное досье. Здесь были мои документы, удостоверение &laquo;Цикад&raquo;, а&nbsp;также фото женщины и&nbsp;девочки, которые обнимали меня... Чтобы не&nbsp;подвергать себя опасности, я&nbsp;покинул здание. Найдя укромное местечко, прошерстил все имеющиеся бумаги. У&nbsp;меня была семья... 

Записи доктора свидетельствовали, что сам я&nbsp;служил в&nbsp;рядах &laquo;Цикад&raquo;. Когда-то давно я&nbsp;вдохнул споры, провалившись под пол ветхого здания. Падение было сильным. Мне чудом удалось выбраться и&nbsp;добраться до&nbsp;лагеря через 12&nbsp;часов. Все думали, что я&nbsp;погиб. После рассказа о&nbsp;ситуации со&nbsp;спорами меня поместили в&nbsp;изолятор одной из&nbsp;больниц штаба. Со&nbsp;времён ужасной бойни в&nbsp;Солт-Лейк-Сити, я&nbsp;стал первым резонансным случаем&nbsp;&mdash; человеком, не&nbsp;проявляющим признаков разгула кордицепса в&nbsp;теле.

После этого я&nbsp;не&nbsp;принадлежал себе... Мне присвоили номер &laquo;000&raquo;, набив его у&nbsp;меня на&nbsp;предплечье. Кучи анализов и&nbsp;лабораторных тестов проводились месяцами. Я&nbsp;не&nbsp;мог вернуться к&nbsp;семье и&nbsp;сбежал. Но&nbsp;сейчас у&nbsp;меня есть всё, чтобы найти своих родных. В&nbsp;документах было указано, что после моей пропажи супруга с&nbsp;дочерью покинули это место и&nbsp;отправились в&nbsp;сторону Джексона. Надеюсь, что они ещё там.
`,
		//(КОНЦОВКА. Хорошая. Зеро узнал всё о&nbsp;себе и&nbsp;отправился искать семью)
		buttons: [{
			to: `K2`,
			text: `Продолжить`,
		}]
	}, {
		scene: `33`,
		text: `&mdash; Рассказывай. Только по&nbsp;делу,&nbsp;&mdash; я&nbsp;решил его выслушать, ведь в&nbsp;медкарте наверняка были не&nbsp;все подробности.
&mdash;&nbsp;Ты&nbsp;был одним из&nbsp;нас! Во&nbsp;время задания ты&nbsp;надышался спор, свалившись в&nbsp;какой-то подвал. Отряд не&nbsp;смог тебя вытащить, да&nbsp;и&nbsp;смысла не&nbsp;было, но&nbsp;ты&nbsp;сам вернулся в&nbsp;лагерь спустя 12&nbsp;часов. Это был шок, ведь кордицепс не&nbsp;превратил тебя в&nbsp;чудовище.
&mdash;&nbsp;Как это получилось?
&mdash;&nbsp;Мы&nbsp;уже слышали об&nbsp;иммунных. Ты&nbsp;один из&nbsp;них! Мы&nbsp;делали тесты, но&nbsp;потом ты&nbsp;попросту пропал,&nbsp;&mdash; его доброжелательный тон удивил меня.
&mdash;&nbsp;А&nbsp;чего вы&nbsp;хотели добиться?
&mdash;&nbsp;Мы&nbsp;искали способ победить кордицепс с&nbsp;твоей помощью! Понятно, что поражённых уже не&nbsp;спасти, но&nbsp;остальным ведь можно постараться подарить иммунитет. Мы&nbsp;боялись, что коммуна удерживает тебя силком. Именно поэтому туда был отправлен отряд. Просто все остальные оказались неспособны передать его. Видишь ту&nbsp;девушку? &mdash;&nbsp;он&nbsp;указал на&nbsp;тело, которое лежало на&nbsp;столе. &mdash;&nbsp;Её&nbsp;привезли уже мёртвой, но&nbsp;тесты показали, что она подходила! Если&nbsp;бы она только осталась жива! Если&nbsp;бы ты&nbsp;согласился на&nbsp;серию тестов...
&mdash;&nbsp;Зачем мне это? Какой от&nbsp;этого толк?
&mdash;&nbsp;У&nbsp;меня есть информация, которую ты&nbsp;точно захочешь получить. И&nbsp;её&nbsp;нет в&nbsp;медкарте! Тесты безвредны. И&nbsp;если всё получится, нам понадобится лишь часть твоих антител.
&mdash;&nbsp;Я&nbsp;убил охранника. 
&mdash;&nbsp;Уверен, что смогу это уладить.`,
		buttons: [{
			to: `32`,
			text: `Не соглашаться и&nbsp;узнать код `,
		}, {
			to: `34`,
			text: `Согласиться на&nbsp;тесты`,
		}]
	}, {
		scene: `34`,
		text: `Сведения, которых нет в&nbsp;карте. Возможность помочь всем живым людям. Это&nbsp;ли не&nbsp;цели, которым стоило последовать? Я&nbsp;согласился. Док повёл меня вниз, а&nbsp;я&nbsp;держал его на&nbsp;мушке. В&nbsp;казарме он&nbsp;объяснил пятерым бойцам ситуацию. Один отправился за&nbsp;трупом охранника, второй пошёл за&nbsp;телом Карли. Они показали мне шкафчик, предложив оставить всё в&nbsp;нём. Агрессии с&nbsp;их&nbsp;стороны не&nbsp;было. Мы&nbsp;вернулись с&nbsp;доком лабораторию. Он&nbsp;предложил мне место на&nbsp;столе. Я&nbsp;сел рядом с&nbsp;небольшим столиком, заваленным инструментами. 

&mdash;&nbsp;Сейчас я&nbsp;введу индикатор, а&nbsp;потом возьму у&nbsp;тебя кровь из&nbsp;пальца. Потерпи.

С&nbsp;этими словами он&nbsp;ввел инъекцию мне в&nbsp;предплечье, а&nbsp;потом намазал
палец спиртом. Не&nbsp;успел он&nbsp;уколоть его, как я&nbsp;начал терять сознание. Прежде чем моя спина коснулась стола, до&nbsp;меня донёсся голос:

&mdash;&nbsp;Прощай, мерзкий выродок...`,
		//(Концовка. Плохая. Зеро доверился &laquo;Цикадам&raquo; и&nbsp;ради науки его умертвили).
		buttons: [{
			to: `K3`,
			text: `Продолжить`,
		}]
	}, {
		scene: `35`,
		text: `Я приподнялся на&nbsp;руках и&nbsp;услышал крик:

&mdash;&nbsp;Шон, этот жив, проверь! Но&nbsp;не&nbsp;вали его, это может быть цель.
&mdash;&nbsp;И&nbsp;правда, на&nbsp;мушке.

Раздался выстрел. Тот, что подходил ко&nbsp;мне, молча упал мордой в&nbsp;песок, разбросав руки и&nbsp;мелко суча ногами в&nbsp;смертельных конвульсиях. Второй боец вскинул винтовку и&nbsp;открыл огонь короткими очередями, мастерски отрезая тройки, по&nbsp;вышке с&nbsp;Майлзом. Не&nbsp;придумав ничего лучше, я&nbsp;запустил в&nbsp;него флягой с&nbsp;пояса и&nbsp;в&nbsp;два прыжка сократил дистанцию, но&nbsp;не&nbsp;устоял на&nbsp;ногах и&nbsp;рухнул прямо на&nbsp;парня. 

Придавленный моим весом, боец врезался носом в&nbsp;землю. Я&nbsp;услышал хруст переносицы и&nbsp;увидел, как пропитывается кровью песок. Внезапно слева раздался сначала какой-то механический звук, а&nbsp;затем, словно отбойный молоток, заработал тяжёлый пулемёт, отправляя тяжёлые 12.7-миллиметровые патроны по&nbsp;наблюдательной вышке. Я&nbsp;увидел, как толстые сосновые опоры начали крошиться, словно спички, а&nbsp;главный наблюдательно-снайперский пункт лагеря покосился и&nbsp;упал, похоронив под собой наш главный козырь. 

Вырвав пистолет из&nbsp;кобуры придавленного мною бойца, тянуться к&nbsp;его карабину не&nbsp;было сил, я&nbsp;с&nbsp;криком начал стрелять по&nbsp;пулемётчику. Лишь третья пуля пробила его шею. Встав на&nbsp;ватных ногах, я&nbsp;направился к&nbsp;вражескому внедорожнику. Оттуда свисал и&nbsp;лил кровь по&nbsp;корпусу подонок, одной рукой ещё державшийся за&nbsp;машину смерти на&nbsp;вертлюге. Сквозь звон в&nbsp;ушах пробился чужеродный, но&nbsp;знакомый звук&nbsp;&mdash; рёв могучего двигателя, с&nbsp;которым ни&nbsp;один джип не&nbsp;сравнится. Забравшись на&nbsp;крышу &laquo;хамви&raquo;, я&nbsp;застонал не&nbsp;только от&nbsp;боли, но&nbsp;и&nbsp;отчаяния&nbsp;&mdash; по&nbsp;прямой к&nbsp;нашему лагерю, пыля огромными покрышками, мчался тентованный грузовик с&nbsp;клиновым отвалом и&nbsp;бронированной кабиной.

Впопыхах я&nbsp;начал вытаскивать тело пулемётчика из&nbsp;ниши, чтобы занять её&nbsp;и&nbsp;дать отпор надвигающейся опасности, но&nbsp;чёртов ублюдок сжал ручку трёхствольного орудия в&nbsp;смертельной хватке. Наконец, мне удалось разбить его пальцы рукояткой пистолета, приведя последний в&nbsp;негодность. Перевалил труп через крышу, как вдруг в&nbsp;правое плечо будто клюшкой для гольфа врезали со&nbsp;всей силы. Я&nbsp;слетел на&nbsp;землю и&nbsp;закричал, сознание начало меркнуть от&nbsp;адской боли, а&nbsp;правая рука повисла, словно съеденная на&nbsp;завтрак макаронина.

&laquo;Чёрт возьми, а&nbsp;вкусные были макароны. Ким умела готовить...&raquo;&nbsp;&mdash; эта приятная, но&nbsp;возникшая совершенно ни&nbsp;к&nbsp;месту мысль была последней в&nbsp;моей голове, прежде чем сознание совершило прыжок веры во&nbsp;тьму. 
`,
		buttons: [{
			to: `36`,
			text: `- Эй, приди в&nbsp;себя!`,
		}]
	}, {
		scene: `36`,
		text: `&mdash; Ну&nbsp;как там?
&mdash;&nbsp;Судя по&nbsp;тестам, нет никаких существенных изменений. Не&nbsp;пострадал. Нужно будет ещё пробовать. Чёрт, вернуть&nbsp;бы нам ту&nbsp;девчонку! Миллер, ублюдок... *хлопок, и&nbsp;я&nbsp;почувствовал вибрацию возле ноги* 
&mdash;&nbsp;А&nbsp;на&nbsp;её&nbsp;след не&nbsp;пробовали выйти?
&mdash;&nbsp;Пробовали, само собой. На&nbsp;поиски злосчастной парочки отправились наши бойцы, но&nbsp;потом бросили эту затею. Штаты большие, они могли убраться в&nbsp;любом направлении, а&nbsp;опытных воинов, особенно с&nbsp;навыками следопытов, у&nbsp;нас осталось очень мало. Сегодня мы&nbsp;потеряли почти половину, и&nbsp;я&nbsp;даже не&nbsp;уверен, что в&nbsp;этом был какой-то смысл. 
&mdash;&nbsp;Ты&nbsp;же здесь давно, и&nbsp;я&nbsp;точно знаю, что больше десятка старых бойцов, и&nbsp;даже лидеров, ушли в&nbsp;&laquo;ВОФ&raquo;.
&mdash;&nbsp;Да, мне тоже предлагали, но&nbsp;предпочёл отказаться.
&mdash;&nbsp;Почему?
&mdash;&nbsp;Слишком много жестокости в&nbsp;них, плюс бесконечная война с&nbsp;&laquo;ФЕДРА&raquo;. Они не&nbsp;как&nbsp;мы. Это оголтелые террористы, бьющие без пощады. Им&nbsp;плевать кого, главное&nbsp;&mdash; во&nbsp;имя чего.
&mdash;&nbsp;Но&nbsp;ты&nbsp;ведь тоже не&nbsp;ангел.
&mdash;&nbsp;Верно, во&nbsp;имя науки и&nbsp;поиска возможного спасения я&nbsp;готов многих прогнать через жернова экспериментов. Но&nbsp;это совсем другое, ведь мы&nbsp;стремимся к&nbsp;лучшей жизни для всех выживших.
&mdash;&nbsp;Хочется верить... Эй, док, он&nbsp;выходит из&nbsp;наркоза. 
&mdash;&nbsp;Зови охрану.

Я&nbsp;пришёл в&nbsp;себя под ярким светом нескольких ламп, лёжа на&nbsp;высокой кровати. По&nbsp;сторонам я&nbsp;видел просто человеческие силуэты, пока глаза адаптировались к&nbsp;освещению. Затем проявились черты&nbsp;&mdash; средних лет мужчина и&nbsp;миловидная девушка в&nbsp;белых халатах и&nbsp;полноценных респираторах, закрывающих их&nbsp;лица. Тут я&nbsp;понял, что лежу вовсе не&nbsp;на&nbsp;кровати, подо мной операционный стол, а&nbsp;эти яйцеголовые уже что-то вырезали... или, наоборот, вживили?

Мужчина посмотрел мне в&nbsp;глаза и&nbsp;произнёс:

&mdash;&nbsp;На&nbsp;сегодня мы&nbsp;с&nbsp;тобой закончили, но&nbsp;это далеко не&nbsp;последняя наша встреча. Я&nbsp;постараюсь добиться хоть каких-то результатов, даже если убью тебя в&nbsp;процессе.
&mdash;&nbsp;...
&mdash;&nbsp;Вайс, забирайте это дело. Он&nbsp;ещё не&nbsp;может полноценно двигаться, но&nbsp;лучше будьте настороже.
&mdash;&nbsp;Конечно, док, обижаешь. Давай, нулевой, потопал. Медленно, сначала правой, потом левой, и&nbsp;повторяй. Это называется ходить.
&mdash;&nbsp;...

Мне многое хотелось ответить&nbsp;им: орать, угрожать, проклинать тех сук, что их&nbsp;породили, мстить за&nbsp;друзей, особенно за&nbsp;Ким. Но&nbsp;я&nbsp;ничего не&nbsp;мог делать. Тело не&nbsp;слушалось, только ноги переступали, а&nbsp;рот бесшумно двигался. Вскоре мы&nbsp;оказались у&nbsp;мощной двери, которую открыли ключом, и&nbsp;по&nbsp;ушам ударил крик зомби. Здесь, похоже, держали всех подопытных, и&nbsp;сейчас я&nbsp;займу место на&nbsp;этой скамье.

Меня завели в&nbsp;клетку, рядом поставили чёрный мешок в&nbsp;ведре для мусора, а&nbsp;ещё один боец принёс тарелку с&nbsp;отвратительно пахнущей стряпнёй. В&nbsp;этой комнате вообще была такая вонь, что хотелось отрезать себе нос или заткнуть его чем угодно. Однако стряпня эта превосходила в&nbsp;омерзительности все остальные ароматы склада подопытных.

Упав на&nbsp;колени, я&nbsp;судорожно подгрёб ведро к&nbsp;себе и&nbsp;начал выворачивать желудок прямо туда, под смех камуфлированных подонков. &laquo;Это конец...&raquo;&nbsp;&mdash; пронеслась мысль в&nbsp;моей голове, и&nbsp;я&nbsp;просто растянулся на&nbsp;полу, не&nbsp;видя смысла в&nbsp;других действиях. Охранники, всё ещё посмеиваясь, вышли из&nbsp;комнаты, не&nbsp;забыв закрыть дверь. Щёлкнул замок...
`,
		buttons: [{
			to: `37`,
			text: `- Возьми себя в&nbsp;руки, тряпка!`,
		}]
	}, {
		scene: `37`,
		text: `Мой внутренний голос продолжал беситься, подбивал на&nbsp;бунт, крики и&nbsp;сопротивление, но&nbsp;тело отказывалось слушаться. Я&nbsp;просто остался лежать на&nbsp;этом полу, вглядываясь во&nbsp;тьму. Истошные крики зомби и&nbsp;вонь вокруг перестали меня волновать, теперь они для меня&nbsp;&mdash; ежедневное сопровождение, сколько&nbsp;бы этих дней ни&nbsp;осталось в&nbsp;моей жизни. 
`,
		buttons: [{
			to: `38`,
			text: `- Не&nbsp;сдавайся!`,
		}]
	}, {
		scene: `38`,
		text: `Зеро уснул и&nbsp;больше ничего не&nbsp;слышит. Он&nbsp;сдался и&nbsp;смирился с&nbsp;отвратительной, жестокой судьбой. Но&nbsp;у&nbsp;тебя, игрок, есть возможность изменить течение этой истории в&nbsp;новом прохождении.`,
		buttons: [{
			to: `0`,
			text: `Начать приключение сначала`,
		}, {
			to: `2`,
			text: `Вернуться к&nbsp;контрольной точке`,
		}]
	}, {
		scene: `K1`,
		text: `В этом непростом путешествии Зеро так ничего и&nbsp;не&nbsp;узнал, но&nbsp;определённо напал на&nbsp;след своего прошлого и&nbsp;не&nbsp;намерен останавливаться на&nbsp;достигнутом. Впереди героя ждёт немало испытаний, противостояние зомби и&nbsp;остаткам &laquo;Цикад&raquo;, а, может, даже встреча с&nbsp;новыми врагами или легендарными персонажами, готовыми оказать помощь. 

Это один из&nbsp;вариантов завершения нашего квеста, но&nbsp;есть и&nbsp;другие. Справитесь&nbsp;ли вы&nbsp;с&nbsp;ними? Независимо от&nbsp;вашего решения, мы, авторы квеста, благодарим вас за&nbsp;уделённое нашему творчеству время и&nbsp;внимание. Надеемся, вам понравилось!

С&nbsp;уважением,
Команда Stratege:

Денис Стороняк&nbsp;&mdash; сценарий
Егор Круцкий&nbsp;&mdash; сценарий
Виталий Щипанов&nbsp;&mdash; редактура
Виктор Литвинов&nbsp;&mdash; дизайн
Артём Ибатуллин&nbsp;&mdash; программирование`,
		buttons: [{
			to: `0`,
			text: `Вернуться к&nbsp;началу приключения`,
		}]
	}, {
		scene: `K2`,
		text: `Вряд&nbsp;ли судьба Зеро могла сложиться удачнее. Потеряв ранее близких друзей, теперь он&nbsp;вновь обрёл смысл жизни. Однако всё могло закончиться не&nbsp;так радужно. Может, вы&nbsp;хотите попробовать пройти игру ещё раз?

Независимо от&nbsp;вашего решения, мы, авторы квеста, благодарим вас за&nbsp;уделённое нашему творчеству время и&nbsp;внимание. Надеемся, вам понравилось!

С&nbsp;уважением,
Команда Stratege:

Денис Стороняк&nbsp;&mdash; сценарий
Егор Круцкий&nbsp;&mdash; сценарий
Виталий Щипанов&nbsp;&mdash; редактура
Виктор Литвинов&nbsp;&mdash; дизайн
Артём Ибатуллин&nbsp;&mdash; программирование`,
		buttons: [{
			to: `0`,
			text: `Вернуться к&nbsp;началу приключения`,
		}]
	}, {
		scene: `K3`,
		text: `Жизнь Зеро оборвалась очень неприятным способом. В&nbsp;этом мире, созданном компанией Naughty Dog, определённо не&nbsp;стоит разбрасываться несколькими вещами&nbsp;&mdash; патронами, аптечками и&nbsp;доверием. Зато стоит разбрасываться кирпичами и&nbsp;бутылками, коих на&nbsp;всех боевых локациях валяется в&nbsp;достатке&nbsp;&mdash; хоть по&nbsp;голове врагов бей, хоть отвлекай.

Попробуйте начать приключение заново и&nbsp;найти другой путь для своего героя. Независимо от&nbsp;вашего решения, мы, авторы квеста, благодарим вас за&nbsp;уделённое нашему творчеству время и&nbsp;внимание. Надеемся, вам понравилось!

С&nbsp;уважением,
Команда Stratege:

Денис Стороняк&nbsp;&mdash; сценарий
Егор Круцкий&nbsp;&mdash; сценарий
Виталий Щипанов&nbsp;&mdash; редактура
Виктор Литвинов&nbsp;&mdash; дизайн
Артём Ибатуллин&nbsp;&mdash; программирование`,
		buttons: [{
			to: `0`,
			text: `Вернуться к&nbsp;началу приключения`,
		}]
	}
];
