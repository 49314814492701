<b-header></b-header>

<section class='section-1'>
	<div class='section-1__background'>
	<canvas #canvasRef></canvas>
	</div>
	<button
		class='section-1__sound'
		(click)='playSound()'
		[class.hidden]='audioPlaying'
	>Включить звук
	</button>
</section>
<section
	class='about'
	id='about'
>
	<h1>Об игре</h1>
	<div
		class='swiper-container'
		[swiper]="config"
		[(index)]="index"
	>
		<!-- Additional required wrapper -->
		<div class='swiper-wrapper'>
			<!-- Slides -->
			<div
				class='swiper-slide'
				[style]='"background-image: url(\"/assets/about/1.jpg\")" | assetsPath | safeStyle'
			>
				<div class='slide-content'>
					<h2><span>Сюжет</span></h2>
					<p>После тяжёлых испытаний в&nbsp;различных уголках США, пройденных при поддержке и&nbsp;защите
						Джоэла, Элли обосновалась в&nbsp;городке Джексон. Эта небольшая коммуна стала для неё самым
						родным домом из&nbsp;всех, что девушка знала в&nbsp;своей не&nbsp;очень долгой жизни. Шли годы,
						она ходила на&nbsp;охоту, читала книги, училась играть на&nbsp;гитаре и&nbsp;стала полезным
						членом общества в&nbsp;пережившем апокалипсис мире.<br><br>

						Привыкшая больше терять, чем обретать, Элли ценила каждую минуту этого спокойствия и&nbsp;размеренной
						жизни, сопряжённой с&nbsp;опасностями, но&nbsp;под защитой стен города и&nbsp;прикрытием людей,
						ставших почти семьёй. Но&nbsp;идиллия разлетелась, словно шайба из&nbsp;снега под ударом тяжёлой
						клюшки, и&nbsp;девушке вновь пришлось столкнуться с&nbsp;призраками прошлого. Теперь у&nbsp;неё
						осталось два выхода&nbsp;&mdash; одолеть&nbsp;их, или проиграть.</p>
				</div>
			</div>
			<div
				class='swiper-slide'
				[style]='"background-image: url(\"/assets/about/2.jpg\")" | assetsPath | safeStyle'
			>
				<div class='slide-content'>
					<h2><span>Герои</span></h2>
					<p>Главной героиней игры &laquo;Одни из&nbsp;нас: Часть&nbsp;II&raquo; становится молодая девушка
						Элли, ключевой персонаж оригинальной &laquo;Одни из&nbsp;нас&raquo; и&nbsp;дополнения &laquo;Одни
						из&nbsp;нас: Оставленные позади&raquo;. Судьба не&nbsp;щадит её&nbsp;с&nbsp;детства&nbsp;&mdash;
						родившаяся уже в&nbsp;рухнувшем мире и&nbsp;обладающая таинственной природы иммунитетом к&nbsp;прорастанию
						гриба в&nbsp;кору головного мозга, она росла в&nbsp;военизированном интернате и&nbsp;потеряла
						немало дорогих людей. После событий первой игры и&nbsp;сложнейшего путешествия через всю Америку
						вместе с&nbsp;Джоэлом, ставшим ей&nbsp;почти отцом, Элли обосновалась в&nbsp;городке Джексон,
						штат Вайоминг.<br><br>

						Но&nbsp;мирная жизнь в&nbsp;этом мире долго не&nbsp;длится, и&nbsp;череда кошмарных событий
						вновь выводит девушку на&nbsp;тропу войны. В&nbsp;&laquo;Одни из&nbsp;нас: Часть&nbsp;II&raquo;
						игроки смогут наблюдать за&nbsp;развитием событий с&nbsp;двух перспектив&nbsp;&mdash; игра
						познакомит с&nbsp;таинственной девушкой Эбби, участницей одной из&nbsp;революционных
						военизированных организаций, чья судьба неразрывно переплелась с&nbsp;судьбою Элли.</p>
				</div>
			</div>
			<div
				class='swiper-slide'
				[style]='"background-image: url(\"/assets/about/3.jpg\")" | assetsPath | safeStyle'
			>
				<div class='slide-content'>
					<h2><span>Враги</span></h2>
					<p>Кордицепс оказался самым коварным врагом в&nbsp;истории человечества. Он&nbsp;не&nbsp;только
						прорастает в&nbsp;мозг людей, разрушая его и&nbsp;вызывая неконтролируемую агрессию, но&nbsp;и&nbsp;постепенно
						мутирует, развивая в&nbsp;носителях разные способности. Первая стадия поражения Кордицепсом
						создаём активных, быстрых, но&nbsp;не&nbsp;выходящих за&nbsp;рамки человеческих сил Бегунов.
						Более длительное и&nbsp;активное поражение мозга превращает носителя в&nbsp;Щелкуна&nbsp;&mdash;
						слепого мутанта, ориентирующегося в&nbsp;пространстве при помощи эхолокации, чью голову
						покрывает прочная грибная пластина, защищающая мозг от&nbsp;прямого урона.<br><br>

						Само собой, на&nbsp;двух мутациях развитие Кордицепса в&nbsp;человеческом теле не&nbsp;завершается,
						постепенно появляются более мощные и&nbsp;смертоносные формы гриба. Среди них выделяются
						огромные Топляки, способные отрывать части своего тела и&nbsp;бросаться ими в&nbsp;людей,
						распространяя спорты, и&nbsp;Шаркуны&nbsp;&mdash; ядовитая эволюция бегунов, от&nbsp;обжигающих
						выделений которых не&nbsp;спасёт ни&nbsp;противогаз, ни&nbsp;крепкая спецодежда.</p>
				</div>
			</div>
			<div
				class='swiper-slide'
				[style]='"background-image: url(\"/assets/about/4.jpg\")" | assetsPath | safeStyle'
			>
				<div class='slide-content'>
					<h2><span>Экипировка</span></h2>
					<p>Для расправы над врагами и&nbsp;выживания Элли доступен широкий арсенал оружия: серийного и&nbsp;самодельного.
						Пистолет, револьвер, винтовка и&nbsp;дробовик могут быть улучшены, но&nbsp;боезапас их&nbsp;ограничен,
						ведь заводы по&nbsp;производству патронов остановлены. Составной лук&nbsp;&mdash; оружие
						искусного охотника, стрелы для которого можно создавать из&nbsp;подручных предметов.<br><br>

						Найденные материалы можно пустить на&nbsp;бомбы-ловушки, светошумовые гранаты и&nbsp;бутылки с&nbsp;зажигательной
						смесью. Чтобы ещё больше развить способности к&nbsp;выживанию и&nbsp;бою, вы&nbsp;будете
						находить обучающие справочники, открывающие доступ к&nbsp;новым веткам навыков&nbsp;&mdash;
						выживание для лучшего здоровья, точность для облегчения прицеливания, а&nbsp;скрытность позволит
						эффективнее расправляться с&nbsp;противниками бесшумно и&nbsp;научит создавать глушители. Есть и&nbsp;другие
						справочники, которые вам предстоит найти самим!</p>
				</div>
			</div>
			<div
				class='swiper-slide'
				[style]='"background-image: url(\"/assets/about/5.jpg\")" | assetsPath | safeStyle'
			>
				<div class='slide-content'>
					<h2><span>Мир</span></h2>
					<p>Цивилизация, какой мы&nbsp;её&nbsp;знаем, рухнула 25&nbsp;лет назад. Паразитирующие на&nbsp;насекомых
						грибы рода Кордицепс мутировали и&nbsp;начали прорастать в&nbsp;человеческих телах, превращая
						своих носителей в&nbsp;безумных зомби, агрессивных и&nbsp;вечно голодных. Теперь они стали
						основными обитателями сотен заброшенных городов по&nbsp;всей Америке.<br><br>

						Выжившие люди начали объединяться в&nbsp;группировки, создавать новые культы и&nbsp;военизированные
						организации, с&nbsp;целью противостояния зомби и&nbsp;аккумуляции мощи для борьбы за&nbsp;ресурсы
						с&nbsp;другими выжившими. Вместе с&nbsp;цивилизацией в&nbsp;мире &laquo;Одни из&nbsp;нас: Часть&nbsp;II&raquo;
						рухнула и&nbsp;мораль&nbsp;&mdash; уже четверть века здесь действует лишь закон джунглей, иногда
						регулируемый военным коммунизмом. Однако не&nbsp;всё так мрачно, ведь даже в&nbsp;этих адских
						условиях ещё осталась любовь, дружба и&nbsp;преданность.</p>
				</div>
			</div>
		</div>
		<!-- If we need pagination -->
		<div class='swiper-pagination'></div>

		<!-- If we need navigation buttons -->
		<div class='swiper-button-prev'></div>
		<div class='swiper-button-next'></div>

		<!-- If we need scrollbar -->
		<!--		<div class='swiper-scrollbar'></div>-->
	</div>
</section>
<section
	class='where-to-buy'
	id='where-to-buy'
>
	<h1>Где купить</h1>
	<h2>Цифровые версии</h2>
	<div class='row'>
		<a
			href='https://store.playstation.com/ru-ru/product/EP9000-CUSA10249_00-THELASTOFUS2DLUX?emcid=di-st-250170&utm_medium=Display&utm_campaign=20200619_siee_displaydirect_thelastofuspartii&utm_source=&utm_term=di-st-250170&utm_content=The_Last_of_Us_Part_II'
			target='_blank'
			rel='noopener'
		>
			<img
				[src]='"/assets/buy1.jpg" | assetsPath'
				alt=''
			>
			Купить Расширенное издание
		</a>
		<a
			href='https://store.playstation.com/ru-ru/product/EP9000-CUSA10249_00-THELASTOFUSPART2?emcid=di-st-250170&utm_medium=Display&utm_campaign=20200619_siee_displaydirect_thelastofuspartii&utm_source=&utm_term=di-st-250170&utm_content=The_Last_of_Us_Part_II'
			target='_blank'
			rel='noopener'
		>
			<img
				[src]='"/assets/buy2.jpg" | assetsPath'
				alt=''
			>
			Купить Стандартное издание
		</a>
	</div>
	<h2>Физические версии</h2>
	<div class='row'>
		<div>
			<img
				[src]='"/assets/phys1.png" | assetsPath'
				alt=''
			>
			Коллекционное издание
		</div>
		<div>
			<img
				[src]='"/assets/phys2.png" | assetsPath'
				alt=''
			>
			Специальное издание
		</div>
		<div>
			<img
				[src]='"/assets/phys3.png" | assetsPath'
				alt=''
			>
			Стандартное издание
		</div>
	</div>
	<a
		href='https://www.playstation.com/ru-ru/games/the-last-of-us-part-ii-ps4.retailers/'
		target='_blank'
		class='btn'
		rel='noopener'
	>КУПИТЬ</a>
</section>
<b-footer></b-footer>
