<div class='author'><span>{{author}}</span></div>
<div class='text'>
	<ng-content></ng-content>
</div>
<div class='link-container'>
	<a
		[href]='link'
		target='_blank'
		rel='noopener'
		class='link'
	>Читать полностью</a>
</div>

<div class='mark-container'>
	<div class='mark'>
		<span class='mark__title'>Оценка</span>
		<ng-container *ngIf='!isGenius'>
			<div class='mark__value'>{{mark}}</div>
			<div class='mark__from'>из {{from}}</div>
		</ng-container>
		<div
			class='mark__genius'
			*ngIf='isGenius'
		>шедевр
		</div>
	</div>
</div>
