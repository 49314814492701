import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { Data, Scene } from '@pages/quest/data';

const validateData = (Data: Array<Scene>) => {
	const allScenes = Data.map(s => s.scene);
	const allButtons = Data.map(s => s.buttons).reduce((acc, val) => acc.concat(val), []);

	const allButtonsHaveTargets = allButtons.every(b => b.to && allScenes.includes(b.to));

	if (!allButtonsHaveTargets) {
		throw new Error('Что-то пошло не так');
	}
};

const MainScenes = Array(39).fill(null).map((_, i) => i.toString());
const SecondaryScenes = ['1A', '2A', '11A', 'K1', 'K2', 'K3'];

const ScenePosition = {
	...Object.keys(MainScenes).reduce((prev, curr) => ({ ...prev, [curr]: +curr }), {}),
	'1A': 1,
	'2A': 2,
	'11A': 11,
	'K1': 30,
	'K2': 32,
	'K3': 34
}

@Component({
	selector: 'b-quest',
	templateUrl: './quest.component.html',
	styleUrls: ['./quest.component.styl'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestComponent {
	currentScene: Scene = this.getScene('hello'); // 'hello'
	visibleScenes = new Set();

	MainScenes = MainScenes;
	SecondaryScenes = SecondaryScenes;
	ScenePosition = ScenePosition;

	@ViewChild('navRef') navRef: ElementRef;

	constructor() {
		validateData(Data);
	}

	centerOnScene(id: string) {
		const index = ScenePosition[id];
		const w = this.navRef.nativeElement.scrollWidth;

		this.navRef.nativeElement.scrollTo({
			left: index * w / 39 - this.navRef.nativeElement.offsetWidth / 2,
			behavior: 'smooth'
		});
	}

	getScene(id: string): Scene {
		return Data.find(s => s.scene === id);
	}

	goTo(id: string) {
		this.visibleScenes.add(id);
		window.scrollTo({
			top: 0,
		});
		this.centerOnScene(id);
		this.currentScene = this.getScene(id);
	}

	isVisible(id: string): boolean {
		return this.visibleScenes.has(id);
	}

	get hasSecondary(): boolean {
		return SecondaryScenes.some(id => this.isVisible(id));
	}

	get isStartScene(): boolean {
		return this.currentScene.scene === 'hello';
	}
}
