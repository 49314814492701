<b-header></b-header>
<main>
	<div class='container'>
		<div class='header'>
			<div
				class='value value--blue'
				title='Количество платиновых трофеев'
			>
				<b-icon icon='trophy'></b-icon>
				{{trophyInfo?.platinum}}
			</div>
			<div
				class='value value--gold'
				title='Количество золотых трофеев'
			>
				<b-icon icon='trophy'></b-icon>
				{{trophyInfo?.gold}}
			</div>
			<div
				class='value value--silver'
				title='Количество серебряных трофеев'
			>
				<b-icon icon='trophy'></b-icon>
				{{trophyInfo?.silver}}
			</div>
			<div
				class='value value--bronze'
				title='Количество бронзовых трофеев'
			>
				<b-icon icon='trophy'></b-icon>
				{{trophyInfo?.bronze}}
			</div>
			<div
				class='value'
				title='Сложность'
			>
				<b-icon icon='lethal'></b-icon>
				{{trophyInfo?.hard.num === -1 ? '—' : trophyInfo?.hard.value}}
			</div>
			<div
				class='value'
				title='Время'
			>
				<b-icon icon='timer'></b-icon>
				{{trophyInfo?.time.num === -1 ? '—' : trophyInfo?.time.value}}
			</div>
		</div>
		<p class='disclaimer'>Информация и подсказки к трофеям предоставлены сайтом Stratege</p>

		<div
			class='trophy'
			*ngFor='let trophy of trophies'
			[class.trophy--blue]='trophy.value.id === TrophyValue.Platinum'
			[class.trophy--gold]='trophy.value.id === TrophyValue.Gold'
			[class.trophy--silver]='trophy.value.id === TrophyValue.Silver'
			[class.trophy--bronze]='trophy.value.id === TrophyValue.Bronze'
		>
			<div class='trophy__header'>
				<img
					[src]='trophy.image'
					alt=''
					class='trophy__image'
				>
				<div class='trophy__content'>
					<div class='trophy__name'>{{trophy.title}}</div>
					<div class='trophy__description'>{{trophy.description}}</div>
					<div class='trophy__progress'>
						<ng-container [ngPlural]='trophy.count_players'>
							<ng-template ngPluralCase='one'>Получил</ng-template>
							<ng-template ngPluralCase='few'>Получили</ng-template>
							<ng-template ngPluralCase='other'>Получили</ng-template>
						</ng-container>
						{{trophy.count_players | number}}
						из {{trophy.count_players_total | number}} игравших
						({{trophy.count_players / trophy.count_players_total | number:'1.0-2'}}%).
					</div>
				</div>

				<button
					class='trophy__helps-count'
					[class.trophy__helps-count--empty]='!trophy.count_helps'
					(click)='toggle(trophy)'
				>
					<ng-container *ngIf='!trophy.count_helps'>Подсказок пока нет</ng-container>
					<ng-container *ngIf='trophy.count_helps'>
						<em>{{trophy.count_helps}}</em>
						<ng-container [ngPlural]='trophy.count_helps'>
							<ng-template ngPluralCase='one'>подсказка</ng-template>
							<ng-template ngPluralCase='few'>подсказки</ng-template>
							<ng-template ngPluralCase='other'>подсказок</ng-template>
						</ng-container>
					</ng-container>
				</button>
			</div>
			<div
				*ngIf='isExpanded(trophy)'
				@slideHeight
			>
				<div class='trophy__helps'>
					<ng-container *ngFor='let help of trophy.helps'>
						<b-trophy-help [help]='help'></b-trophy-help>
						<b-trophy-help
							*ngFor='let child of help.children'
							[isChild]='true'
							[help]='child'
						></b-trophy-help>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</main>
<b-footer></b-footer>
