import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ReviewsService } from '@services/reviews.service';
import { Review } from '@models/review.interface';

@Component({
	selector: 'b-reviews-players',
	templateUrl: './reviews-players.component.html',
	styleUrls: ['./reviews-players.component.styl'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewsPlayersComponent implements OnInit {
	reviews: Array<Review>;

	constructor(
		private cdr: ChangeDetectorRef,
		private reviewsService: ReviewsService
	) {
		this.reviewsService.getReviews()
			.subscribe(res => {
				this.reviews = res;
				this.cdr.markForCheck();
			});
	}

	ngOnInit(): void {
	}

}
