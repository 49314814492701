import { Component, Input } from '@angular/core';

@Component({
	selector: 'b-review',
	templateUrl: './review.component.html',
	styleUrls: ['./review.component.styl']
})
export class ReviewComponent {
	@Input() author;
	@Input() link;

	@Input() mark;
	@Input() from;
	@Input() isGenius;
}
