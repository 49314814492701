import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'b-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.styl']
})
export class SubmenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
