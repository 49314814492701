import { Pipe, PipeTransform } from '@angular/core';
import { assetsPath } from '@utils/assets-path';

@Pipe({
	name: 'assetsPath'
})
export class AssetsPathPipe implements PipeTransform {
	transform(value: string): string {
		return assetsPath(value);
	}
}
