<div
	class='video'
	*ngFor='let video of videos'
>
	<iframe
		[src]="'https://www.youtube.com/embed/' + video.id | safeUrl"
		frameborder="0"
		allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
		allowfullscreen
	></iframe>
	<a href='https://youtu.be/{{video.id}}' target='_blank' rel='noopener'>{{video.name}}</a>
</div>
