<b-review
	author='Дмитрий Яковенко (Stratege)'
	link='https://www.stratege.ru/ps4/games/the_last_of_us_part_ii/article/99544'
	mark='90'
	from='100'
>Это самая большая и&nbsp;одновременно самая неоднозначная игра Naughty
	Dog. Она бесподобна визуально, а&nbsp;душевная музыка заставляет забыть о&nbsp;том, какой из&nbsp;двух миров
	настоящий. Разработчики набрались мастерства в&nbsp;вопросе левел-дизайна и&nbsp;геймплея. Вместо пресноватой
	стрельбы первой части, здесь реализовали напряжённые схватки с&nbsp;хитроумным искусственным интеллектом врагов, а&nbsp;шикарная
	физика тел и&nbsp;оружия делают этот опыт особенно памятным.<br><br>

	В&nbsp;глухие чащи пошёл лишь сюжет. Он&nbsp;намеренно расшатывает психику игрока, совершая неожиданные повороты и&nbsp;рубя
	с&nbsp;плеча по&nbsp;самому дорогому. Когда настаёт момент отвечать за&nbsp;содеянное, сценаристы уходят в&nbsp;затягивание
	истории и&nbsp;в&nbsp;тщетные попытки заставить нас сопереживать другим героям. Но&nbsp;это не&nbsp;работает, так
	как искупление приходит через боль и&nbsp;долгие душевные терзания. А&nbsp;персонажи здесь слишком поверхностны и&nbsp;действуют
	в&nbsp;ограниченных временных рамках.<br><br>

	Однако своей цели разработчики достигают&nbsp;&mdash; концовка пробирает до&nbsp;мурашек и&nbsp;заставляет испытать
	сильные эмоции. Остаётся лишь восхищаться магией видеоигр как искусства и&nbsp;размышлять о&nbsp;бренности бытия.
</b-review>
<b-review
	author='Александр Логинов (GameMAG)'
	link='https://gamemag.ru/reviews/the-last-of-us-part-2-ps4-pro'
	mark='9'
	from='10'
>The Last of&nbsp;Us&nbsp;Part II&nbsp;&mdash; это высшая точка развития PlayStation 4&nbsp;и&nbsp;хоть сюжетно и&nbsp;спорное,
	но&nbsp;невероятное затягивающее приключение Элли, от&nbsp;которого невозможно оторваться от&nbsp;вступительных
	титров и&nbsp;до&nbsp;самого конца.
</b-review>
<b-review
	author='Алексей Пилипчук (Игромания)'
	link='https://www.igromania.ru/article/31334/Obzor_The_Last_of_Us_Part_II_Oda_perfekcionizmu.html'
	[isGenius]='true'
>Игра-событие, которую, как и&nbsp;первую часть, наверняка будут годами любить одни и&nbsp;рьяно ненавидеть&nbsp;&mdash;
	другие. Только в&nbsp;этот раз поводов для споров будет даже больше.
</b-review>

<div class='form'>
<p>Если вы хотите, чтобы ваша рецензия появилась на этой странице, заполните специальную форму</p>
<a
	href='https://docs.google.com/forms/d/e/1FAIpQLSefv2WF8apULYplI6BAdIJ1DIBvJRli0yMJ7fGIKRj_SJ9mwQ/viewform?usp=sf_link'
	target='_blank'
	rel='noopener'
	class='btn'
>ЗАПОЛНИТЬ ФОРМУ</a>
</div>
