import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Review } from '@models/review.interface';

@Component({
	selector: 'b-player-message',
	templateUrl: './player-message.component.html',
	styleUrls: ['./player-message.component.styl'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlayerMessageComponent {
	@Input() message: Review;
}
