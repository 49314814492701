import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { assetsPath } from '@utils/assets-path';
import { init } from './background';

@Component({
	selector: 'b-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.styl']
})
export class HomeComponent implements AfterViewInit {
	@ViewChild('canvasRef') canvasRef: ElementRef;

	config = {
		// autoHeight: true, //enable auto height
		// If we need pagination
		breakpoints: {
			1200: {
				autoHeight: false,
			},
		},

		pagination: {
			el: '.swiper-pagination',
		},

		// // Navigation arrows
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
	};

	index = 0;
	audio;
	audioPlaying = false;

	constructor() {
		this.audio = new Audio(assetsPath('/assets/sound.mp3'));
	}

	ngAfterViewInit() {
		init(this.canvasRef.nativeElement);
	}

	playSound() {
		this.audio.play();
		this.audioPlaying = true;

	}
}
