<header class='header header--desktop hide-on-mobile'>
	<a href='#where-to-buy'>Где купить</a>
	<a href='#about'>Об игре</a>
	<a
		routerLinkActive='active'
		routerLink='/gallery'
	>Галерея</a>
	<a
		routerLink='/reviews'
		routerLinkActive='active'
	>Отзывы</a>
	<a
		href=''
		class='header__logo'
	>
		<img
			width='100'
			[src]='"/assets/logo.png" | assetsPath'
			[srcset]='"/assets/logo.png, /assets/logo@2x.png 2x" | assetsPath'
			alt=''
		>
	</a>
	<a
		routerLink='/quest'
		routerLinkActive='active'
	>Квест</a>
	<a
		routerLink='/trophies'
		routerLinkActive='active'
	>Трофеи</a>
	<a
		routerLink='/walkthrough'
		routerLinkActive='active'
	>Прохождение
	</a>
	<a
		routerLink='/contest'
		routerLinkActive='active'
	>Конкурс <span>Завершён!</span></a>
</header>

<header class='header header--mobile hide-on-desktop'>
	<a
		href=''
		class='header__logo'
	>
		<img
			width='100'
			[src]='"/assets/logo.png" | assetsPath'
			[srcset]='"/assets/logo.png, /assets/logo@2x.png 2x" | assetsPath'
			alt=''
		>
	</a>
</header>

<nav
	class='mobile-menu'
	[style.height.px]='mobileMenuHeight'
	[class.mobile-menu--active]='isOpen'

	#mobileMenuRef
>
	<div class='mobile-menu__content'>
		<a
			(click)='close()'
			href='#where-to-buy'
		>Где купить</a>
		<a
			(click)='close()'
			href='#about'
		>Об игре</a>
		<a
			routerLinkActive='active'
			(click)='close()'
			routerLink='/gallery'
		>Галерея</a>
		<a
			routerLink='/reviews'
			routerLinkActive='active'
			(click)='close()'
		>Отзывы</a>
		<a
			routerLink='/quest'
			routerLinkActive='active'
			(click)='close()'
		>Квест</a>

		<a
			routerLink='/trophies'
			routerLinkActive='active'
			(click)='close()'
		>Трофеи</a>
		<a
			routerLink='/walkthrough'
			routerLinkActive='active'
		>Прохождение</a>
		<a
			routerLink='/contest'
			routerLinkActive='active'
			(click)='close()'
		>Конкурс <span>Завершён!</span></a>
	</div>
</nav>

<button
	class='burger hide-on-desktop'
	[class.burger--active]='isOpen'
	(click)='burgerClick()'
>
	<span></span><span></span><span></span>
</button>
