<b-header></b-header>

<main [class.isStartScene]='isStartScene'>
	<div class='container'>
		<nav class='scene-nav' #navRef>
			<div class='scene-nav__primary'>
				<button
					*ngFor='let i of MainScenes'
					[class.visible]='isVisible(i)'
					(click)='goTo(i)'
				>{{i}}</button>
			</div>
			<div
				class='scene-nav__secondary'
				*ngIf='hasSecondary'
			>
				<button
					*ngFor='let i of SecondaryScenes'
					[style.left.%]='ScenePosition[i] * 2.564'
					[class.visible]='isVisible(i)'
					(click)='goTo(i)'
				>{{i}}</button>
			</div>
		</nav>
		<div
			class='title'
			*ngIf='!currentScene.hideTitle'
		><span>Сцена {{currentScene.scene}}</span></div>
		<div
			class='content'
			[innerHTML]='currentScene.text'
		></div>

		<div class='buttons'>
			<button
				class='btn btn--smaller-padding'
				*ngFor='let b of currentScene.buttons'
				(click)='goTo(b.to)'
				[innerHTML]='b.text'
			></button>
		</div>
	</div>
</main>
<b-footer></b-footer>
