import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TrophyInfo } from '@models/trophy-info.interface';
import { Trophy } from '@models/trophy.interface';

@Injectable({
	providedIn: 'root'
})
export class TrophyService {

	constructor(private http: HttpClient) {
	}

	getTrophyInfo(): Observable<TrophyInfo> {
		return this.http.get<TrophyInfo>('https://app.stratege.ru/trophies_list/19879');
	}

	getTrophies(): Observable<Array<Trophy>> {
		return this.http.get<Array<Trophy>>('https://app.stratege.ru/trophies/trophies_list_expanded/19879');
	}
}
