import * as THREE from 'three';
import { BlurMaterial, NoiseMaterial } from '@pages/home/shader';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { VerticalBlurShader } from 'three/examples/jsm/shaders/VerticalBlurShader';
import { SobelOperatorShader } from 'three/examples/jsm/shaders/SobelOperatorShader';
import { ClearPass } from 'three/examples/jsm/postprocessing/ClearPass';
import { assetsPath } from '@utils/assets-path';

let width, height, camera, renderer, scene, mesh, backgroundMaterial, particleGeo, canvas, composer;

export function init(c) {
	canvas = c;
	scene = new THREE.Scene();
	camera = new THREE.PerspectiveCamera(
		5,
		window.innerWidth / window.innerHeight,
		0.1,
		1000,
	);
	camera.position.z = 100;
	camera.updateProjectionMatrix();

	renderer = new THREE.WebGLRenderer({ canvas, alpha: true });
	// renderer.autoClear = false;
	renderer.setClearColor(0x000000, 0); // the default

	composer = new EffectComposer( renderer );

	// VerticalBlurShader.uniforms.v = 10 / 512;
	// SobelOperatorShader.uniforms.resolution = [1, 1];
	// BlurMaterial.uniforms.maskTexture = new THREE.TextureLoader().load(assetsPath(('/assets/rays_mask.png')));
	const blurPass = new ShaderPass(BlurMaterial);
	blurPass.renderToScreen = true;
	// blurPass.uniforms.

	const renderPass = new RenderPass( scene, camera );
	// renderPass.clear = false;

	// var clearPass = new ClearPass();

	composer.addPass( renderPass );
	composer.addPass( blurPass );


	addGeometry();
	resize();
	animate();
}

function addGeometry() {
	const geometry = new THREE.PlaneBufferGeometry(1, 1);
	backgroundMaterial = new THREE.ShaderMaterial(NoiseMaterial);
	backgroundMaterial.transparent = true;
	// const material = new THREE.MeshBasicMaterial({color: 0xff0000, wireframe: true});
	mesh = new THREE.Mesh(geometry, backgroundMaterial);

	// particleGeo = new THREE.Geometry();
	// for (let i = 0; i < 6000; i++) {
	// 	const particle = new THREE.Vector3(
	// 		Math.random() * 20 - 10,
	// 		Math.random() * 20 - 10,
	// 		Math.random() * 50 + 25,
	// 	);
	// 	particleGeo.vertices.push(particle);
	// }
	// const sprite = new THREE.TextureLoader().load(particle);
	// const particleMaterial = new THREE.PointsMaterial({
	// 	color: 0x999999,
	// 	size: 0.01,
	// 	map: sprite,
	// 	blending: THREE.AdditiveBlending, transparent: true,
	// });
	// const particles = new THREE.Points(particleGeo, particleMaterial);
	// scene.add(particles);

	scene.add(mesh);
}

function resize() {
	width = canvas.offsetWidth;
	height = canvas.offsetHeight;
	camera.aspect = width / height;

	const vFOV = (THREE as any).Math.degToRad(camera.fov); // convert vertical fov to radians
	const h = 2 * Math.tan(vFOV / 2) * 100; // visible height
	const w = h * 1.7777; //camera.aspect;
	mesh.scale.set(w, h, 1);

	camera.updateProjectionMatrix();
	renderer.setSize(width, height);
	// composer.setSize(width, height);
}

function animate(time = 0) {
	requestAnimationFrame(animate);

	if (backgroundMaterial.uniforms) {
		backgroundMaterial.uniforms.time.value = time;
	}
	composer.render();

	// renderer.render(scene, camera);
}

window.addEventListener('resize', resize);
