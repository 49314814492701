<b-header></b-header>

<main>
	<div class='container'>
		<p>1. Конкурс проводится с&nbsp;19.06.2020 по&nbsp;12.07.2020&nbsp;включительно. Итоги
			подводятся в&nbsp;течение пяти рабочих дней после окончания конкурса.</p>
		<p>2. Участниками конкурса могут стать жители любой страны, однако доставка призов осуществляется только на&nbsp;территории Российской Федерации.</p>
		<p>3. Для участия необходимо создать иллюстрацию на&nbsp;тему игры &laquo;Одни из&nbsp;нас: Часть&nbsp;II&raquo; и&nbsp;прислать
		её&nbsp;на&nbsp;конкурс вместе с&nbsp;заявлением на&nbsp;участие через специальную форму находящуюся по&nbsp;адресу:
			<a href='https://forms.gle/ES5kJf2ps5aNtgR29' target='_blank' rel='noopener'>https://forms.gle/ES5kJf2ps5aNtgR29</a></p>
		<p>4. Нет никаких творческих и&nbsp;технических ограничений.</p>
		<p>5. Присланная работа должна быть выполнена самим участником. Участник приславший чужую работу будет
		дисквалифицирован.</p>
		<p>6. Принимается только одна работа от&nbsp;одного участника. Если выяснится, что один и&nbsp;тот&nbsp;же участник
		прислал несколько работ (под одним или нескольким именами), все работы участника будут сняты с&nbsp;конкурса, а&nbsp;участник
		дисквалифицирован. Тем не&nbsp;менее, участник в&nbsp;течение всего времени конкурса может заменить свою работу.</p>
		<p>7. Победитель определяется составом жюри, в&nbsp;который входят редакторы портала Stratege.</p>
		<p>8. Призы:</p>
		<p class='tab'><b>1-е место</b>: Игровая консоль Sony PlayStation 4&nbsp;PRO 1TB в&nbsp;издании The Last of&nbsp;Us&nbsp;Part II&nbsp;Limited
		Edition</p>
		<p class='tab'><b>2-е место</b>: Игра &laquo;Одни из&nbsp;нас: Часть&nbsp;II&raquo; в&nbsp;издании Collector&rsquo;s Edition</p>
		<p class='tab'><b>3-е место</b>: Контроллер для PlayStation 4&nbsp;DualShock 4&nbsp;в издании The Last of&nbsp;Us&nbsp;Part II&nbsp;Edition</p>
		<p class='tab'><b>4-е место</b> и&nbsp;5-е место: Карта оплаты 5500&nbsp;руб. для пополнения бумажника в&nbsp;PlayStation Store</p>
		<p>9. Все призы являются российскими версиями. Карты оплаты предназначены только для российского PlayStation Store.</p>
		<p>10. Связь с&nbsp;победителем осуществляется через указанный в&nbsp;заявлении на&nbsp;участие аккаунт одной из&nbsp;социальных
		сетей: ArtStation, Twitter или DeviantArt. Если победитель не&nbsp;выходит на&nbsp;связь в&nbsp;течение недели
		после объявления результатов, его приз может быть передан другому участнику конкурса.</p>
		<p>11. Все призы доставляются в&nbsp;течение 3&nbsp;месяцев с&nbsp;момента подведения итогов.</p>
		<p>12. Условия конкурса могут быть изменены в&nbsp;зависимости от&nbsp;обстоятельств.</p>
	</div>
</main>

<b-footer></b-footer>
