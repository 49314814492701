import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { HomeComponent } from '@pages/home/home.component';
import { GalleryComponent } from '@pages/gallery/gallery.component';
import { HeaderComponent } from '@components/header/header.component';
import { SWIPER_CONFIG, SwiperConfigInterface, SwiperModule } from 'ngx-swiper-wrapper';
import { PhotosComponent } from '@pages/gallery/photos/photos.component';
import { ArtComponent } from '@pages/gallery/art/art.component';
import { FooterComponent } from '@components/footer/footer.component';
import { VideoComponent } from '@pages/gallery/video/video.component';
import { SafeUrlPipe } from '@pipes/safe-url.pipe';
import { AssetsPathPipe } from '@pipes/assets-path.pipe';
import { SafeStylePipe } from '@pipes/safe-url1.pipe';
import { ReviewsComponent } from '@pages/reviews/reviews.component';
import { ReviewsPressComponent } from '@pages/reviews/reviews-press/reviews-press.component';
import { SubmenuComponent } from '@components/submenu/submenu.component';
import { ReviewComponent } from '@pages/reviews/reviews-press/review/review.component';
import { QuestComponent } from '@pages/quest/quest.component';
import { ContestComponent } from '@pages/contest/contest.component';
import { RulesComponent } from '@pages/contest/rules/rules.component';
import { ReviewsPlayersComponent } from '@pages/reviews/reviews-players/reviews-players.component';
import { HttpClientModule } from '@angular/common/http';
import { MessageParserComponent } from '@components/message-parser/message-parser.component';
import { PlayerMessageComponent } from '@pages/reviews/reviews-players/player-message/player-message.component';
import { TrophiesComponent } from '@pages/trophies/trophies.component';
import { IconComponent } from '@components/icon/icon.component';
import { TrophyHelpComponent } from '@pages/trophies/trophy-help/trophy-help.component';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import localeRuExtra from '@angular/common/locales/extra/ru';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GalleryListComponent } from './components/gallery/gallery-list/gallery-list.component';
import { GalleryPreviewComponent } from './components/gallery/gallery-preview/gallery-preview.component';
import { GalleryDetailComponent } from './components/gallery/gallery-detail/gallery-detail.component';
import { WalkthroughComponent } from './pages/walkthrough/walkthrough.component';

registerLocaleData(localeRu, localeRuExtra);

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
	direction: 'horizontal',
	slidesPerView: 'auto'
};

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		GalleryComponent,
		HeaderComponent,
		PhotosComponent,
		ArtComponent,
		FooterComponent,
		VideoComponent,
		SafeUrlPipe,
		AssetsPathPipe,
		SafeStylePipe,
		ReviewsComponent,
		ReviewsPressComponent,
		SubmenuComponent,
		ReviewComponent,
		QuestComponent,
		ContestComponent,
		RulesComponent,
		ReviewsPlayersComponent,
		MessageParserComponent,
		PlayerMessageComponent,
		TrophiesComponent,
		IconComponent,
		TrophyHelpComponent,
		GalleryListComponent,
		GalleryPreviewComponent,
		GalleryDetailComponent,
		WalkthroughComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		SwiperModule,
		HttpClientModule,
		BrowserAnimationsModule
	],
	providers: [
		{
			provide: SWIPER_CONFIG,
			useValue: DEFAULT_SWIPER_CONFIG
		},
		{ provide: LOCALE_ID, useValue: 'ru' }
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
