import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Review } from '@models/review.interface';

@Injectable({
	providedIn: 'root'
})
export class ReviewsService {

	constructor(private http: HttpClient) {
	}

	getReviews(): Observable<Array<Review>> {
		return this.http.get<Array<Review>>('https://app.stratege.ru/games/impressions/99724');
	}
}
