import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TrophyInfo } from '@models/trophy-info.interface';
import { TrophyService } from '@services/trophy.service';
import { Trophy } from '@models/trophy.interface';
import { TrophyValue } from '@models/trophy-value.enum';
import { SlideHeight } from '@utils/animations';

@Component({
	selector: 'b-trophies',
	templateUrl: './trophies.component.html',
	styleUrls: ['./trophies.component.styl'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [SlideHeight]
})
export class TrophiesComponent {
	trophyInfo: TrophyInfo;
	trophies: Array<Trophy>;

	TrophyValue = TrophyValue;

	expanded = new Set();

	constructor(
		private trophyService: TrophyService,
		private cdr: ChangeDetectorRef
	) {
		this.trophyService.getTrophyInfo()
			.subscribe(res => {
				this.trophyInfo = res;
				this.cdr.markForCheck();
			});
		this.trophyService.getTrophies()
			.subscribe(res => {
				this.trophies = res;
				this.cdr.markForCheck();
			});
	}

	toggle(trophy: Trophy): void {
		if (!trophy.count_helps) {
			return;
		}

		if (this.isExpanded(trophy)) {
			this.expanded.delete(trophy.id);
		} else {
			this.expanded.add(trophy.id);
		}
	}

	isExpanded(trophy: Trophy): boolean {
		return this.expanded.has(trophy.id);
	}
}
