import { RouterModule, Routes } from '@angular/router';
import { GalleryComponent } from '@pages/gallery/gallery.component';
import { HomeComponent } from '@pages/home/home.component';
import { NgModule } from '@angular/core';
import { PhotosComponent } from '@pages/gallery/photos/photos.component';
import { ArtComponent } from '@pages/gallery/art/art.component';
import { VideoComponent } from '@pages/gallery/video/video.component';
import { ReviewsComponent } from '@pages/reviews/reviews.component';
import { ReviewsPressComponent } from '@pages/reviews/reviews-press/reviews-press.component';
import { QuestComponent } from '@pages/quest/quest.component';
import { ContestComponent } from '@pages/contest/contest.component';
import { RulesComponent } from '@pages/contest/rules/rules.component';
import { ReviewsPlayersComponent } from '@pages/reviews/reviews-players/reviews-players.component';
import { TrophiesComponent } from '@pages/trophies/trophies.component';
import { WalkthroughComponent } from '@pages/walkthrough/walkthrough.component';

const routes: Routes = [
	{
		path: '',
		component: HomeComponent
	}, {
		path: 'gallery',
		component: GalleryComponent,
		children: [{
			path: '',
			redirectTo: '/gallery/photos',
			pathMatch: 'full'
		}, {
			path: 'photos',
			component: PhotosComponent
		}, {
			path: 'arts',
			component: ArtComponent
		}, {
			path: 'video',
			component: VideoComponent
		}]
	}, {
		path: 'reviews',
		component: ReviewsComponent,
		children: [{
			path: '',
			redirectTo: '/reviews/press',
			pathMatch: 'full'
		}, {
			path: 'press',
			component: ReviewsPressComponent
		}, {
			path: 'players',
			component: ReviewsPlayersComponent
		}]
	}, {
		path: 'quest',
		component: QuestComponent
	}, {
		path: 'contest',
		component: ContestComponent
	}, {
		path: 'contest/rules',
		component: RulesComponent
	}, {
		path: 'trophies',
		component: TrophiesComponent
	}, {
		path: 'walkthrough',
		component: WalkthroughComponent
	}];


@NgModule({
	imports: [RouterModule.forRoot(routes, {
		scrollPositionRestoration: 'enabled',
		anchorScrolling: 'enabled',
	})],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
