import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrophyHelp } from '@models/trophy-help.interface';

@Component({
	selector: 'b-trophy-help',
	templateUrl: './trophy-help.component.html',
	styleUrls: ['./trophy-help.component.styl'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrophyHelpComponent {
	@Input() help: TrophyHelp;
	@Input() isChild = false;
}
