<div
	class='gallery-view'
	*ngIf='isOpened'
>
	<div
		class='gallery-view__viewport'
	>
		<button
			class='gallery-view__button gallery-view__button--prev'
		></button>
		<button
			class='gallery-view__button gallery-view__button--next'
		></button>

		<swiper
			[config]="config"
			[index]="index"
			(indexChange)="indexChange.emit($event)"
		>
			<div
				class='swiper-slide'
				*ngFor='let image of images; let i = index'
			>
				<img
					[attr.data-src]='image.big'
					alt=''
					class='swiper-lazy'
				>
				<div class="swiper-lazy-preloader"></div>
			</div>
		</swiper>
	</div>
	<button
		class='gallery-view__close'
		(click)='close.emit()'
	>
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M0.885969 24.8496C-0.285604 26.0212 -0.285604 27.9207 0.885969 29.0922C2.05754 30.2638 3.95704 30.2638 5.12861 29.0922L14.9823 19.2385L24.8496 29.1058C26.0212 30.2774 27.9207 30.2774 29.0922 29.1058C30.2638 27.9342 30.2638 26.0347 29.0922 24.8631L19.225 14.9959L29.0995 5.12132C30.2711 3.94975 30.2711 2.05025 29.0995 0.878679C27.928 -0.292894 26.0285 -0.292893 24.8569 0.878679L14.9823 10.7532L5.12132 0.892227C3.94975 -0.279346 2.05025 -0.279345 0.87868 0.892227C-0.292893 2.0638 -0.292893 3.9633 0.87868 5.13487L10.7397 14.9959L0.885969 24.8496Z" />
		</svg>
	</button>
</div>
