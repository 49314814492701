<div class='header'>
	<div class='title'>
		<b-icon icon='key-card' size='25'></b-icon>
		{{isChild ? 'Дополнение к подсказке' : 'Подсказка'}} от {{help.author.name}}
		— {{help.date | date:'dd.MM.yyyy в HH:mm'}}
	</div>
	<div class='hearts'>
	<b-icon icon='full-heart' size='20'></b-icon>
	{{help.hearts}}
	</div>
</div>
<b-message-parser [message]='help.text'></b-message-parser>
