<button
	(click)='open.emit()'
>
	<img
		[src]='image.small'
		alt=''
	>
</button>

<ng-content></ng-content>
