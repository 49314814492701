import { Component, ElementRef, ViewChild } from '@angular/core';
import innerHeight from 'ios-inner-height';
import scrollLock from 'scroll-lock';

@Component({
	selector: 'b-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.styl']
})
export class HeaderComponent {
	isOpen = false;
	mobileMenuHeight;
	@ViewChild('mobileMenuRef') mobileMenuRef: ElementRef;

	burgerClick() {
		this.isOpen = !this.isOpen;

		if (this.isOpen) {
			this.mobileMenuHeight = innerHeight();
			scrollLock.disablePageScroll(this.mobileMenuRef.nativeElement);
		} else {
			scrollLock.enablePageScroll(this.mobileMenuRef.nativeElement);
		}
	}

	close() {
		this.isOpen = false;
		scrollLock.enablePageScroll(this.mobileMenuRef.nativeElement);
	}
}
