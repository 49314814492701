import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
	name: 'safeStyle'
})
export class SafeStylePipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {

	}

	transform(value: string): SafeResourceUrl {
		return this.sanitizer.bypassSecurityTrustStyle(value);
	}
}
