<b-header></b-header>

<main class='gallery'>
	<div class='container'>
		<b-submenu>
			<a
				routerLinkActive='active'
				routerLink='/gallery/photos'
			>Снимки</a>
			<a
				routerLinkActive='active'
				routerLink='/gallery/video'
			>Видео</a>
			<a
				routerLinkActive='active'
				routerLink='/gallery/arts'
			>Фан-арт</a>
		</b-submenu>
		<div class='gallery-content'>
			<router-outlet></router-outlet>
		</div>
	</div>
</main>
<b-footer></b-footer>
