<b-header></b-header>

<main>
	<div class='container'>
		<b-submenu>
			<a
				routerLinkActive='active'
				routerLink='/reviews/press'
			>Пресса</a>
			<a
				routerLinkActive='active'
				routerLink='/reviews/players'
			>
				Игроки
			</a>
		</b-submenu>
		<router-outlet></router-outlet>
	</div>
</main>
<b-footer></b-footer>
