import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'b-video',
	templateUrl: './video.component.html',
	styleUrls: ['./video.component.styl']
})
export class VideoComponent {
	videos = [{
		name: 'Одни из нас: Часть II | Официальный ролик к выходу игры | PS4',
		id: 'MAacsAPSp9w'
	}, {
		name: 'Одни из нас: Часть II | Знакомство с миром (субтитры) | PS4',
		id: 'r7UFlWmIn70'
	}, {
		name: 'Одни из нас: Часть II | Официальный расширенный рекламный ролик | PS4',
		id: 'TkbzkXWz6i0'
	}, {
		name: 'Одни из нас: Часть II | State of Play | PS4',
		id: 'WP2P22eSUV4'
	}, {
		name: 'Одни из нас: Часть II | Создание игрового процесса (субтитры) | PS4',
		id: '3ID24lvCYcM'
	}, {
		name: 'Одни из нас: Часть II | Специальное издание PS4 Pro',
		id: 'de62EUOZEDQ'
	}, {
		name: 'Одни из нас: Часть II | Рождение сюжета (субтитры) | PS4',
		id: 'HDkksMaW0WM'
	}, {
		name: '«Одни из нас: Часть II» | Официальный сюжетный трейлер | PS4',
		id: '9FK_FQwrORg'
	}];
}
