<b-header></b-header>

<main>
	<div class='container'>
		<div class='plate'>Конкурс завершён!</div>

		<div class='places'>

			<div class='place place--1'>
				<div class='place__winner'><img
					src='/assets/art/part2/0907-07.jpg'
					alt=''
				></div>
				<div class='place__wrapper'>
					<div class='place__title'>1-е место</div>
					<img
						src='/assets/prize1.png'
						alt=''
						class='place__image'
					>
					<div class='place__link'>
						<a

							href='https://twitter.com/ErmakLitvinenko'
							target='_blank'
							rel='noopener noreferrer'
						>
							Ермак
							<b-icon
								icon='twitter'
								size='16'
							></b-icon>
						</a>
					</div>
				</div>
			</div>

			<div class='place place--2'>
				<div class='place__winner'>
					<img
						src='/assets/art/part2/0307-01.gif'
						alt=''
					>
				</div>

				<div class='place__wrapper'>
					<div class='place__title'>2-е место</div>
					<img
						src='/assets/prize2.png'
						alt=''
						class='place__image'
					>
					<div class='place__link'>
						<a

							href='https://twitter.com/sixveeceear'
							target='_blank'
							rel='noopener noreferrer'
						>
							6VCR
							<b-icon
								icon='twitter'
								size='16'
							></b-icon>
						</a>
					</div>
				</div>
			</div>

			<div class='place place--3'>
				<div class='place__winner'>
					<img
						src='/assets/art/part2/1107-06.jpg'
						alt=''
					>
				</div>

				<div class='place__wrapper'>
					<div class='place__title'>3-е место</div>
					<img
						src='/assets/prize3.png'
						alt=''
						class='place__image'
					>
					<div class='place__link'>
						<a

							href='https://www.deviantart.com/japantuning'
							target='_blank'
							rel='noopener noreferrer'
						>
							JapantuninG
							<b-icon
								icon='deviantart'
								size='16'
							></b-icon>
						</a>
					</div>
				</div>
			</div>

			<div class='row'>

				<div class='place place--4'>
					<div class='place__winner'><img
						src='/assets/art/part2/1107-04.jpg'
						alt=''
					></div>

					<div class='place__wrapper'>
						<img
							src='/assets/prize4.png'
							alt=''
							class='place__image'
						>
						<div class='place__title'>4-е место</div>
						<div class='place__link'>
							<a

								href='https://twitter.com/insomniac_li'
								target='_blank'
								rel='noopener noreferrer'
							>
								Insomnia
								<b-icon
									icon='twitter'
									size='16'
								></b-icon>
							</a>
						</div>
					</div>
				</div>
				<div class='place place--4'>
					<div class='place__winner'>
						<img
							src='/assets/art/part2/1107-02.jpg'
							alt=''
						>
					</div>

					<div class='place__wrapper'>
						<img
							src='/assets/prize4.png'
							alt=''
							class='place__image'
						>
						<div class='place__title'>5-е место</div>
						<div class='place__link'>
							<a

								href='https://www.artstation.com/muravey4ik'
								target='_blank'
								rel='noopener noreferrer'
							>
								Тимофей Муравьёв
								<b-icon
									icon='artstation'
									size='16'
								></b-icon>
							</a>
						</div>
					</div>
				</div>
			</div>
			<p class='center'>Работы остальных участников, а также работы, присланные вне конкурса, в том числе иностранными художниками можно посмотреть в
				<a routerLink='/gallery/arts'>галерее фан-арта</a></p>
			<p class='center'><a routerLink='/contest/rules'>Правила проведения конкурса</a></p>
			<!--			<a-->
			<!--				href='https://docs.google.com/forms/d/e/1FAIpQLSdpuikhM1LYxiqFEgRTEtIG8pwk57N4FkQbkM9bJYWxutIPlQ/viewform?usp=sf_link'-->
			<!--				target='_blank'-->
			<!--				rel='noopener'-->
			<!--				class='btn'-->
			<!--			>Принять участие</a>-->
			<!--			<p class='disclaimer'>Посылая заявку на участие в конкурсе вы автоматически соглашаетесь с <a routerLink='/contest/rules'>правилами его проведения</a></p>-->
		</div>
	</div>
</main>
<b-footer></b-footer>
